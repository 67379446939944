import React, { useContext, useState, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Body, Box, Card, FilterBar, Filter, Pagination, SearchResultsGrid } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { filterQuery, history, unpackFilterQuery } from 'helpers/search';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { SearchableSection } from 'search/SearchableSection';
import ImageNotFound from 'assets/resultmissing.png';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
  padding: 0 12px;

  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }    

  margin-bottom: 10px;
  display: block;
  padding: 48px;
`;

const PaginationContainer = styled(Box)`
  max-width: 320px;
  margin: auto;
  margin-top: 48px;

  @media (min-width: 1025px) {
      max-width: 800px;
  }
  @media (max-width: 1024px) {
      max-width: 640px;
  }    
`;

function compareKeys(a, b) {
  return (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0;
}

const EventCalendar = React.memo(() => {
    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const noResultsText = translateInterfaceText("No Results Found");
    const loadingText = translateInterfaceText("Loading");

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const initialTerm = params.get('s');
    const initialFilter = unpackFilterQuery(params.get('f'));
    const initialPage = params.get('p');

    const [query, setQuery] = useState(null);

    const date = new Date();

    const labels = {
      dateRange: translateInterfaceText('Date Range'),
      audiences: translateInterfaceText('Audiences'),
      category: translateInterfaceText('Category'),
      location: translateInterfaceText('Location')
    };

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'event',
        page: initialPage || 1,
        term: initialTerm || '',
        limit: 9,
        filters: initialFilter || { 
          dateRange: [
            new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().slice(0, 10),
            new Date(date.getFullYear(), date.getMonth()+1, date.getDate()).toISOString().slice(0, 10)
          ] 
        }
      },  
    );

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);
  
    const handleFilterChange = filter => val => {
      const { filters } = options;
      const payload = filters ? { ...filters, [filter]: [val] } : { [filter]: [val] };
      dispatch({ type: 'setFilters', payload });
      history.push(`/visit/calendar?s=${options.term}&f=${filterQuery(payload)}&p=1`);
    };

    const handleRangeChange = val => {
      const payload = { dateRange: val };
      dispatch({ type: 'setFilters', payload: payload});
      history.push(`/visit/calendar?s=${options.term}&f=${filterQuery(payload)}&p=1`);
    };

    const handleTermChange = val => {
      dispatch({ type: 'setTerm', payload: val });
      history.push(`/visit/calendar?s=${val}&f=${filterQuery(options.filters)}&p=1`);
    };

    const handlePageChange = val => {
      dispatch({ type: 'setPagination', payload: { page: val, limit: options.limit }});
      history.push(`/visit/calendar?s=${options.term}&f=${filterQuery(options.filters)}&p=${val}`);
      window.scrollTo(0, 0);
    };

    return (
      <Container>
        <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/visit/calendar"}>
          {({ results, loading }) => {
        
            const audienceOptions = [{ label: 'Everyone', value: '' }];
            const categoryOptions = [{ label: 'Any', value: '' }];
            const locationOptions = [{ label: 'Any', value: '' }];
        
            if (results?.aggregations?.audience)
              results.aggregations.audience.sort(compareKeys).map(agg => { audienceOptions.push({ label: agg.key, value: agg.key }); });
        
            if (results?.aggregations?.category)
              results.aggregations.category.sort(compareKeys).map(agg => { categoryOptions.push({ label: agg.key, value: agg.key }); });
        
            if (results?.aggregations?.location)
              results.aggregations.location.sort(compareKeys).map(agg => { locationOptions.push({ label: agg.key, value: agg.key }); });
        
            const dateRangeDefaults = (options.filters?.dateRange) ? { from: options.filters.dateRange[0], to: options.filters.dateRange[1] } : undefined;
            const audienceDefaults = (options.filters?.audiences) ? { value: options.filters.audiences } : undefined;
            const categoryDefaults = (options.filters?.category) ? { value: options.filters.category } : undefined;
            const locationDefaults = (options.filters?.location) ? { value: options.filters.location } : undefined;

            return (
            <>
              <Box mb="24px">
                <FilterBar onSearch={handleTermChange} value={options.term}> 
                  <Filter variant="dateRange" label={labels.dateRange} defaults={dateRangeDefaults} onChange={handleRangeChange} />
                  <Filter options={audienceOptions} label={labels.audiences} onChange={handleFilterChange('audiences')} defaults={audienceDefaults} />
                  <Filter options={categoryOptions} label={labels.category} onChange={handleFilterChange('category')} defaults={categoryDefaults} />
                  <Filter options={locationOptions} label={labels.location} onChange={handleFilterChange('location')} defaults={locationDefaults} />
                </FilterBar>
              </Box>
              {results?.hits && results.hits.length > 0 && !loading && (
                <SearchResultsGrid cardsPerRow={3}>
                  {results.hits.map((result, index) => (
                    <Link key={index} to={{ pathname: result.url }}>
                      <Card 
                        title={result.title}
                        caption={result.field_date_rrule.map((date, cindex) => <Body variant="small" key={cindex}>
                          {formatInTimeZone(fromUnixTime(date), 'America/Chicago', 'EEE, MMM do yyyy')} 
                          {(formatInTimeZone(fromUnixTime(result.date_rrule_end[cindex]), 'America/Chicago', 'EEE, MMM do yyyy') !=
                            formatInTimeZone(fromUnixTime(date), 'America/Chicago', 'EEE, MMM do yyyy')) && 
                            (<> - {formatInTimeZone(fromUnixTime(result.date_rrule_end[cindex]), 'America/Chicago', 'EEE, MMM do yyyy')}</>)
                          }
                          <br />
                          {formatInTimeZone(fromUnixTime(date), 'America/Chicago', 'h:mm aaa')} - {formatInTimeZone(fromUnixTime(result.date_rrule_end[cindex]), 'America/Chicago', 'h:mm aaa')}
                        </Body>)}
                        image={result.thumbnail || result.featured_image || ImageNotFound}
                        />
                      </Link>
                  ))}
                </SearchResultsGrid>
              )}
              {results?.hits?.length === 0 && !loading && (
                <Box w="100%" pt="48px">
                  <Body textAlign="center">{noResultsText}</Body>
                </Box>
              )}
              {loading && (
                <Box w="100%" pt="48px">
                  <Body textAlign="center">{loadingText}...</Body>
                </Box>
              )}
              <PaginationContainer>
                <Pagination
                  currentPage={results?.currentPage || 1}
                  pageCount={results?.pageCount || 1}
                  onChange={handlePageChange}
                />
              </PaginationContainer>
            </>
          );
          }}
        </SearchableSection>
      </Container>
    );
});

export default EventCalendar;
