import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Body, Box, CollectionConstituent, CollectionItemHeader, CollectionItemDescription, Image, Button, purple, red } from 'dma-ui-kit';
import ImageNotFound from 'assets/missing.png';
import styled from 'styled-components';
import CollectionSlideShow from '../../molecules/collection/CollectionSlideShow';
import { ObjectImage } from '../../molecules/collection/ObjectImage';
import { translateInterfaceText } from 'helpers/translation';
import ReactToPrint from 'react-to-print';
import { ObjectToPrint } from './ObjectToPrint';
import { buildIIIFImageURL, buildIIIFManifestURL } from 'helpers/collection';
import { withRouter } from 'react-router';

const FunFacts = styled(Box)``;
const AudioLinks = styled(Box)``;
const VideoLinks = styled(Box)``;
const VirtualMedia = styled(Box)``;
const BlogArticles = styled(Box)``;

const IIIFLink = styled(Link)`
    color: ${purple};
`;

const PrintContainer = styled(Box)`
display: none;
`;

const MarginContainer = styled(Box)`
width: 80%:
padding: 0 12px;

@media (min-width: 769px) {
    padding: 0 24px;
}
@media (min-width: 1025px) {
    padding: 0 48px;
}
`;

const ImageContainer = styled(Box)`
    width: 100%:
    height: 400px;
    padding: 0 12px;

    @media (min-width: 769px) {
        padding: 0 24px;
    }
    @media (min-width: 1025px) {
        padding: 0 48px;
    }
`;

const ClickBox = styled(Box)`
    text-decoration: ${props => props.onClick ? 'underline' : ''};
    cursor: ${props => props.onClick ? 'pointer' : ''};
`;

const PopulatedCollectionCarousel = ( props ) => {
    return (
        <>
            {props.images.length ? (
                    <ImageContainer>
                        <CollectionSlideShow {...props} />
                    </ImageContainer>
                ) : (
                    <ImageContainer>
                        <ObjectImage src={ImageNotFound} />
                    </ImageContainer>
                )
            }
        </>
    );
};

const ObjectContainer = ({ history, data }) => {
    const { id, images, pyramid, title } = data;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handleLink = slug => () => history.navigate(slug);

    // Used to pass image to print 
    const printingRef = useRef();

    // IIIF Manifest location
    const iiifManifestURL = buildIIIFManifestURL( id );

    const labels = {
        classification: translateInterfaceText('Classification'), 
        copyright: translateInterfaceText('Copyright'),
        creditline: translateInterfaceText('Credit Line'), 
        culture: translateInterfaceText('Culture'),
        date: translateInterfaceText('Date'), 
        department: translateInterfaceText('Department'), 
        dimensions: translateInterfaceText('Dimensions'), 
        download: translateInterfaceText('Download'),
        iiif: translateInterfaceText('IIIF'),
        level: translateInterfaceText('Level'),
        location: translateInterfaceText('Location'), 
        maker: translateInterfaceText('Maker'), 
        materials: translateInterfaceText('Material and Technique'), 
        number: translateInterfaceText('Object Number'),
        print: translateInterfaceText('Print'),
        share: translateInterfaceText('Share')
    };

    const notOnView = translateInterfaceText('Currently not on view');
    const searchBackLink = (filter, name) => { history.push(`/art/collection/search?s=&f=${filter}||${name}|||&p=1`); };

    const itemContent = {
        body: data.label_copy,
        objectDetails: []
    };

    if (data.constituents?.length > 0)
        itemContent.objectDetails.push({ label: labels.maker, data: (<>{data.constituents.map((c, i) => <CollectionConstituent key={i} name={c.name} nationality={c.nationality} onClick={() => searchBackLink('artist', c.name)} role={c.role} yearEnd={c.end_year} yearStart={c.begin_year} />)}</>) });

    if (data.culture?.length > 0)
        itemContent.objectDetails.push({ label: labels.culture, data: (<ClickBox onClick={() => searchBackLink('culture', data.culture)}><Body>{data.culture}</Body></ClickBox>) });

    if (data.dated?.length > 0)
        itemContent.objectDetails.push( { label: labels.date, data: (<Body>{data.dated}</Body>) } );

    if (data.department?.length > 0)
        itemContent.objectDetails.push( { label: labels.department, data: (<ClickBox onClick={() => searchBackLink('department', data.department)}><Body>{data.department}</Body></ClickBox>) } );

    if (data.classification?.length > 0)
        itemContent.objectDetails.push( { label: labels.classification, data: (<Body>{data.classification}</Body>) } );

    if (data.medium?.length > 0)
        itemContent.objectDetails.push( { label: labels.materials, data: (<ClickBox onClick={() => searchBackLink('medium', data.medium)}><Body>{data.medium}</Body></ClickBox>) } );

    if (data.dimensions?.length > 0)
        itemContent.objectDetails.push( { label: labels.dimensions, data: (<Body>{data.dimensions}</Body>) } );

    const location = (data.location?.length > 0) ?    
        (<>{data.location.map((loc, i) => <Body key={i}>{loc.name}{loc.floor && `, ${labels.level} ${loc.floor}`}</Body>)}</>) :
        (<Body>{notOnView}</Body>);
    itemContent.objectDetails.push( { label: labels.location, data: location } );

    if (data.number?.length > 0)
        itemContent.objectDetails.push( { label: labels.number, data: (<Body>{data.number}</Body>) } );

    if (data.credit_line?.length > 0)
        itemContent.objectDetails.push( { label: labels.creditline, data: (<Body>{data.credit_line}</Body>) } );

    if (data.copyright?.credit_line.length > 0)
        itemContent.objectDetails.push( { label: labels.copyright, data: (<Body>{data.copyright?.credit_line}</Body>) } );

    const downloadImage = () => {
        const href = buildIIIFImageURL(id, images[currentImageIndex], {size: 'full'});

        fetch(href, {
            method: "GET",
            headers: {}
        }).then(
            response => {
                response.arrayBuffer().then(function(buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${id}_${images[currentImageIndex]}.jpg`);
                    document.body.appendChild(link);
                    link.click();                
                });
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    };

    return (
        <>
            <PopulatedCollectionCarousel 
                id={id} 
                images={images} 
                pyramid={pyramid} 
                action={setCurrentImageIndex}
            />

            <MarginContainer>
                <CollectionItemHeader title={title || ''}>
                    { pyramid &&
                    <Button
                        variant='icon'
                        label={labels.download}
                        icon='download'
                        color={red}
                        onClick={() => downloadImage()}
                    />
                    }
                    { pyramid &&
                    <ReactToPrint
                        trigger={() => 
                            <Button
                                variant='icon'
                                label={labels.print}
                                icon='print'
                                color={red}
                            />
                        }
                        content={() => printingRef.current}
                    />          
                    }          
                    {/* <Button
                        variant='icon'
                        label={labels.share}
                        icon='share'
                        color={red}
                        onClick={() => { console.log(`Sharing ${id}`); }}
                    /> */}
                    <Button
                        variant='hovericon'
                        label={labels.iiif}
                        icon='iiif'
                        color={red}
                        onClick={() => { 
                            navigator.clipboard.writeText(iiifManifestURL);
                            alert("IIIF Manifest copied to clipboard"); 
                        }}
                        content={[
                            <Body key={1}></Body>,
                            <Body key={2} color={purple} variant="tiny" bold>Image Interoperatbility Framework</Body>,
                            <Body key={3}><hr /></Body>,
                            <Body key={4} variant="tiny">IIIF provides researchers rich metadata and media viewing options for comparison of works across cultural heritage collections.</Body>,
                            <Body key={5}><hr /></Body>,
                            <Body key={6} color={purple} variant="tiny" bold style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(iiifManifestURL); alert("IIIF Manifest copied to clipboard"); }}>Copy Manifest</Body>,
                            <Body key={7} variant="tiny" bold style={{ cursor: 'pointer' }}><IIIFLink to={{ pathname: 'https://uv-v4.netlify.app/#?manifest='+iiifManifestURL }} target={'_blank'}>View in Universal Viewer</IIIFLink></Body>
                        ]}                        
                    />
                </CollectionItemHeader>
            </MarginContainer>

            <CollectionItemDescription content={itemContent} />
            
            <FunFacts data={data} />
            <AudioLinks data={data} />
            <VideoLinks data={data} />
            <VirtualMedia data={data} />
            <BlogArticles data={data} />
            
            <PrintContainer>
                <ObjectToPrint src={buildIIIFImageURL(id, images[currentImageIndex], {size: 'full'} )} ref={printingRef} />
            </PrintContainer>
        </>
    );
};

export default withRouter(ObjectContainer);