import React from "react";
import { FlexBox, Image } from 'dma-ui-kit';
import styled from "styled-components";

const DisplayBox = styled(FlexBox)`
    height: 650px;
    margin: auto;
`;

export const ObjectImage = ({ alt, style, onclick, src, title }) => {
    return (
        <DisplayBox>
            <Image alt={alt} title={title} style={style} onclick={onclick} src={src} fit="contain" />
        </DisplayBox>
    );
};

export const ObjectTile = ({ alt, title, style, onclick, size, src }) => {
    const pxSize = size ? size + 'px' : '200px';
    return (
        <FlexBox minWidth={pxSize} minHeight={pxSize} mr="10px">
            <Image alt={alt} title={title} style={style} onclick={onclick} src={src} />
        </FlexBox>
    );
};
