import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';
import { Image, TwoColumn } from 'dma-ui-kit';
import styled from 'styled-components';

const PublicationImage = styled(Image)`
    max-width: 300px;
`;

const PublicationPage = ({ pageManifest }) => {

  const { field_featured_image, field_paragraph } = pageManifest.relationships;
  const children = generatePageParagraphs(field_paragraph.data);

  const image = field_featured_image.data?.relationships?.field_media_image?.data?.attributes?.uri?.url ?
    field_featured_image.data.relationships.field_media_image.data.attributes.uri.url :
    undefined;

  return (
    <> 
        {image ? 
            <TwoColumn variant="xl-right">
                <div><PublicationImage src={image} /></div>
                <div>{children}</div>
            </TwoColumn> :
            {children}
        }
    </>
  );
};

export default PublicationPage;
