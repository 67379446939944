import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';
import { Body, Box, Button, H6, red, SocialShare, TwoColumn } from 'dma-ui-kit';
import styled from 'styled-components';
import { translateInterfaceText } from 'helpers/translation';
import { Link } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';

// Event Page - Figma Comp 1.2.1

const Container = styled(Box)`
  padding: 0 0 0 12px;
  @media (min-width: 1025px) {
      padding: 0 0 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 0 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const ImageContainer = styled(Box)`
    height: 300px;
    margin: auto;
    text-align: center;
    margin-bottom: 12px;
    max-width: 1250px;

    padding: 0 12px;

    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }
  
`;

const Image = styled.img`
    max-height: 300px;
    margin: auto;
`;

const EventPage = ({ pageManifest }) => {
    const { attributes, relationships } = pageManifest;
    
    const {
        field_additional_info,
        field_date_rrule,
        field_member_purchase_ticket_url,
        field_members_cost,
        field_members_only,
        field_purchase_ticket_url,
        field_regular_cost,
        title
    } = attributes;

    const {
        field_audiences,
        field_event_category,
        field_featured_image,
        field_location,
        field_paragraph
    } = relationships;
    
    const children = generatePageParagraphs(field_paragraph.data);

    const field_related_exhibition = relationships.field_related_exhibition? relationships.field_related_exhibition : null;

    return (
        <Container> 
            <TwoColumn variant='wide-left'>
                <div>
                    {field_featured_image.data && field_featured_image.data.relationships?.field_media_image?.data &&
                        <ImageContainer>
                            <Image src={field_featured_image.data.relationships.field_media_image.data.attributes.uri.url} />
                        </ImageContainer> 
                    }
                    {children}
                </div>
                <div>
                    <Box>
                        <H6>{translateInterfaceText('Dates')}</H6>
                        {field_date_rrule.map((item, idx) => 
                            <Box key={idx} mb="5px">
                                <Body variant="small" key={idx}>
                                    {formatInTimeZone(Date.parse(item.value), 'America/Chicago', 'MMM do yyyy')} 
                                    {(formatInTimeZone(Date.parse(item.value), 'America/Chicago', 'MMM do yyyy') !=
                                    formatInTimeZone(Date.parse(item.end_value), 'America/Chicago', 'MMM do yyyy')) && 
                                    (<> - {formatInTimeZone(Date.parse(item.end_value), 'America/Chicago', 'MMM do yyyy')}</>)
                                    }
                                    <br />
                                    {formatInTimeZone(Date.parse(item.value), 'America/Chicago', 'h:mm aaa')} - {formatInTimeZone(Date.parse(item.end_value), 'America/Chicago', 'h:mm aaa')}
                                </Body>
                            </Box>
                        )}
                        {/* {field_custom_date && <Body>{field_custom_date}</Body>} */}
                    </Box>

                    {field_location.data && field_location.data.attributes &&
                        <Box mt={'10px'}>
                            <H6>{translateInterfaceText('Location')}</H6>
                            <Body variant="small">{field_location.data.attributes.name}</Body>
                        </Box>
                    }

                    {field_related_exhibition && field_related_exhibition.data && field_related_exhibition.data.attributes &&
                        <Box mt={'10px'}>
                            <H6>{translateInterfaceText('Related Exhibition')}</H6>
                            
                            <Link to={field_related_exhibition.data.attributes.alias}>
                                <Body variant="small">{field_related_exhibition.data.attributes.title}</Body>
                                <Body variant="small">
                                    {formatInTimeZone(Date.parse(field_related_exhibition.data.attributes.field_start_date), 'America/Chicago', 'MMM do yyyy')} -
                                    {formatInTimeZone(Date.parse(field_related_exhibition.data.attributes.field_end_date), 'America/Chicago', 'MMM do yyyy')}
                                </Body>
                            </Link>
                        </Box>
                    }


                    <Box mt={'10px'}>
                        <H6>{translateInterfaceText('Ticket Info')}</H6>

                        <Body variant="small" bold={true}>{translateInterfaceText('Members Only')}</Body>
                        <Body variant="small">{(field_members_only && field_members_only != 0) ? 'Yes' : 'No'}</Body>

                        <Body variant="small" bold={true}>{translateInterfaceText('Member Tickets')}</Body>
                        <Body variant="small">{(field_members_cost && field_members_cost != 0) ? `$${field_members_cost}` : translateInterfaceText('Free')}</Body>
                        {field_member_purchase_ticket_url &&
                            <Box mt={'10px'}>
                                <Link 
                                to={{ pathname: field_member_purchase_ticket_url.uri }}
                                target="_blank"
                                rel="noopener noreferrer">
                                    <Button 
                                        label={field_member_purchase_ticket_url.title}
                                        variant={'outline'}
                                        compact={true}
                                        color={red}
                                    />
                                </Link>
                            </Box>
                        }

                        <Body variant="small" bold={true}>{translateInterfaceText('Public Tickets')}</Body>
                        <Body variant="small">{(field_regular_cost && field_regular_cost != 0) ? `$${field_regular_cost}` : translateInterfaceText('Free')}</Body>
                        {field_purchase_ticket_url &&
                            <Box mt={'10px'}>
                                <Link 
                                    to={{ pathname: field_purchase_ticket_url.uri }}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <Button 
                                        label={field_purchase_ticket_url.title}
                                        variant={'outline'}
                                        compact={true}
                                        color={red}
                                    />
                                </Link>
                            </Box>
                        }
                    </Box>

                    {field_additional_info && 
                        <Box mt={'10px'}>
                            <H6>{translateInterfaceText('Additional Info')}</H6>
                            <Body>{field_additional_info}</Body>
                        </Box>
                    }
                    
                    {field_event_category.data && field_event_category.data.attributes &&
                        <Box mt={'10px'}>
                            <H6>{translateInterfaceText('Category')}</H6>
                            <Body>{field_event_category.data.attributes.name}</Body>
                        </Box>
                    }

                    {field_audiences.data && field_audiences.data[0] &&
                        <Box mt={'10px'}>
                            <H6>{translateInterfaceText('Audiences')}</H6>
                            {field_audiences.data.map((item, idx) => <Body key={idx}>{item.attributes.name}</Body>)}
                        </Box>
                    }

                    <Box mt={'10px'}>
                        <H6>{translateInterfaceText('Share This')}</H6>
                        <SocialShare
                            title={title}
                            media={field_featured_image?.data && field_featured_image.data.relationships?.field_media_image?.data && field_featured_image.data.relationships.field_media_image.data.attributes.uri.url}
                            color={red} 
                        />
                    </Box>
                </div>
            </TwoColumn>
        </Container>
    );
};

export default EventPage;
