import React, { useContext, useState, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Body, Box, Card, FilterBar, Filter, Pagination, SearchResultsGrid } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { filterQuery, history, unpackFilterQuery } from 'helpers/search';
import { SearchableSection } from 'search/SearchableSection';
import ImageNotFound from 'assets/resultmissing.png';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
    padding: 0 12px;

    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }    

    margin-bottom: 10px;
    display: block;
`;

const PaginationContainer = styled(Box)`
    max-width: 320px;
    margin: auto;
    margin-top: 48px;

    @media (min-width: 1025px) {
        max-width: 800px;
    }
    @media (max-width: 1024px) {
        max-width: 640px;
    }    
`;

function compareKeys(a, b) {
  return (a.key < b.key) ? -1 : (a.key > b.key) ? 1 : 0;
}

const ExhibitionSearch = React.memo(() => {
    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const noResultsText = translateInterfaceText("No Results Found");
    const loadingText = translateInterfaceText("Loading");

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const date = new Date();

    const initialTerm = params.get('s');
    const initialFilter = unpackFilterQuery(params.get('f'));
    const initialPage = params.get('p');
    const shortCut = params.get('sh');

    const defaultFilter = { status: ['current'] };

    if (['past', 'upcoming'].includes(shortCut))
      defaultFilter.status = [ shortCut ];

    const labels = {
      location: translateInterfaceText('Location'),
      status: translateInterfaceText('Status')
    };

    const [query, setQuery] = useState(null);

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'exhibition',
        page: initialPage || 1,
        term: initialTerm || '',
        limit: 9,
        filters: initialFilter || defaultFilter
       },  
    );

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);
  
    const handleFilterChange = filter => val => {
      const { filters } = options;
      const payload = filters ? { ...filters, [filter]: [val] } : { [filter]: [val] };

      dispatch({ type: 'setFilters', payload });
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/exhibitions?s=${options.term}&f=${filterQuery(payload)}&p=1`);
    };

    const handleTermChange = val => {
      dispatch({ type: 'setTerm', payload: val });
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/exhibitions?s=${val}&f=${filterQuery(options.filters)}&p=1`);
    };

    const handlePageChange = val => {
      history.push(`/art/exhibitions?s=${options.term}&f=${filterQuery(options.filters)}&p=${val}`);
      dispatch({ type: 'setPagination', payload: { page: val, limit: options.limit }});
      window.scrollTo(0, 0);
    };

    const yearOptions = [];
    for (var i = 2013; i <= date.getFullYear()+3; i++) { yearOptions.push( {value: i, label: i} ); }

    return (
        <Container>
          <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/art/exhibitions"}>
            {({ results, loading }) => {

              const locationOptions = [{ label: 'Any', value: '' }];
              const statusOptions = [ 
                { label: 'Any', value: '' }, 
                { label: 'Current' , value: 'current' },
                { label: 'Upcoming' , value: 'upcoming' },
                { label: 'Past' , value: 'past' } 
              ];
        
              if (results?.aggregations?.location)
                results.aggregations.location.sort(compareKeys).map(agg => { locationOptions.push({ label: agg.key, value: agg.key }); });

              const locationDefaults = (options.filters?.location) ? { value: options.filters.location } : undefined;
              const statusDefaults = (options.filters?.status) ? { value: options.filters.status } : undefined;

              return(
              <>
                <Box mb="24px">
                  <FilterBar onSearch={handleTermChange} value={options.term}> 
                    <Filter options={statusOptions} label={labels.status} onChange={handleFilterChange('status')} defaults={statusDefaults} />
                    <Filter options={locationOptions} label={labels.location} onChange={handleFilterChange('location')} defaults={locationDefaults} />
                  </FilterBar>
                </Box>
                {results?.hits && results.hits.length > 0 && !loading && (
                  <SearchResultsGrid cardsPerRow={3}>
                    {results.hits.map((result, index) => {
                        const url = (result.field_microsite_link && result.field_microsite_link[0]) ? result.field_microsite_link[0] : result.url[0];
                        return (
                          <Link key={index} to={{ pathname: url }} target={url.includes('http') ? '_blank' : null}>
                            <Card 
                              title={result.title}
                              caption={(<Body variant="small">
                                {formatInTimeZone(fromUnixTime(result.field_start_date), 'America/Chicago', 'MMM do, yyyy')} - {formatInTimeZone(fromUnixTime(result.field_end_date), 'America/Chicago', 'MMM do, yyyy')}
                              </Body>)}
                              image={result.featured_image || ImageNotFound}
                              />
                          </Link>
                      );
                    })}
                  </SearchResultsGrid>
                )}
                {results?.hits?.length === 0 && !loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{noResultsText}</Body>
                  </Box>
                )}
                {loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{loadingText}...</Body>
                  </Box>
                )}
                <PaginationContainer>
                  <Pagination
                    currentPage={results?.currentPage || 1}
                    pageCount={results?.pageCount || 1}
                    onChange={handlePageChange}
                  />
                </PaginationContainer>
              </>
            );}}
          </SearchableSection>
        </Container>
      );
});

export default ExhibitionSearch;
