import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import { Footer } from 'dma-ui-kit';
import { get } from 'lodash';
import { SiteContext } from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';

const PageFooter = ( props ) => {
  const { history } = props;
  const { siteData } = useContext(SiteContext);
  const leftLinks = get(siteData, 'menus.footer-1.data', []);
  const rightLinks = get(siteData, 'menus.footer-2.data', []);

  const navigation = {
    right: [],
    left: []
  };

  leftLinks.map(link => { navigation.left.push( {label: link.attributes.title, slug: link.attributes.url} ); });
  rightLinks.map(link => { navigation.right.push( {label: link.attributes.title, slug: link.attributes.url} ); });

  const labels = {
    newsletter: translateInterfaceText('Sign up for our newsletter'),
    social: translateInterfaceText('Follow Us'),
    scroll: translateInterfaceText('Back to Top')
  };

  const content = siteData?.site_footer?.body ? siteData.site_footer.body : '';

  const actions = {
    navigate: slug => history.push(slug)
  };

  return (
      <Footer
          labels={labels}
          content={content}
          actions={actions}
          navigation={navigation}
      />
  );
};

export default withRouter(PageFooter);