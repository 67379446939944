import React from 'react';
import { buildIIIFImageURL } from 'helpers/collection';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'dma-ui-kit';

const CollectionListItem = props => {
    const { paragraph } = props;

    const title = paragraph.attributes.field_title;
    const query = paragraph.attributes.field_query;

    const { field_collection_object, field_collection_image } = paragraph.relationships.field_display_collection_image.data.attributes;

    return (
        <Link to={`/art/collection/search?${query}`}>
            <Card title={title} caption="" image={buildIIIFImageURL(field_collection_object, field_collection_image, { size: 200 })} variant="mini" center />
        </Link>
    );
};

export default withRouter(CollectionListItem);