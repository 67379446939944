import { useContext } from "react";
import { get } from "lodash";
import SiteContext from "site/SiteContext";
import { translateInterfaceText } from "./translation";

import { RRule } from 'rrule';
import { compareAsc,startOfDay } from 'date-fns';
import { format, zonedTimeToUtc } from 'date-fns-tz';

// Transform Drupal Open hours format into standard time
const toStandardTime = (drupalTime) => {
    drupalTime = drupalTime.toString();

    const date = new Date();
    date.setHours(Number((drupalTime.length == 3) ? `0${drupalTime.charAt(0)}` : `${drupalTime.charAt(0)}${drupalTime.charAt(1)}`));
    date.setMinutes(Number((drupalTime.length == 3) ? `${drupalTime.charAt(1)}${drupalTime.charAt(2)}` : `${drupalTime.charAt(2)}${drupalTime.charAt(3)}`));
    date.setSeconds(0);
    return format(date, 'h:mm aa');
};

function checkIfToday(rruleStr){
    // All comparisons are UTC due to source data
    var rule = RRule.fromString(rruleStr);
    var todayutc = zonedTimeToUtc(startOfDay(new Date()));
    var nextOccurrence = zonedTimeToUtc(rule.after(todayutc, true));
    var match = compareAsc(todayutc, nextOccurrence) == 0;
  
    return match;
};

export const getOpenHours = () => {
    const { siteData } = useContext(SiteContext);

    const closed = translateInterfaceText('Closed Today');

    const dayOfWeek = new Date().getDay();
    let openHours = '';

    if (get(siteData, 'open_hours')) {
        openHours = closed;

        if (siteData.open_hours.hours[dayOfWeek])
            openHours = `${toStandardTime(siteData.open_hours.hours[dayOfWeek].open)} - ${toStandardTime(siteData.open_hours.hours[dayOfWeek].close)}`;

        for (const exc of siteData.open_hours.exclusion)
            if (checkIfToday(exc))
                openHours = closed;
    }

    return openHours;
};
