import React from 'react';
import { Link } from 'react-router-dom';
import { generatePageParagraphs } from 'cms/parsers';
import { Box, FlexBox, TwoColumn } from 'dma-ui-kit';
import styled from 'styled-components';


const Container = styled(Box)`
  padding: 0 0 0 12px;
  @media (min-width: 1025px) {
      padding: 0 0 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 0 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const PaddedBox = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    
`;

const AudioRecording = ({ pageManifest }) => {
    const { field_audio, field_audio_category, field_image, field_paragraph, field_transcript } = pageManifest.relationships;
    const children = generatePageParagraphs(field_paragraph.data);

    const recordingContent = () => {
        return (
            <>
                {children}

                {field_audio && field_audio.data.relationships.field_media_audio_file.data.attributes.uri.url &&
                    <PaddedBox>
                        <audio controls>
                            <source src={field_audio.data?.relationships?.field_media_audio_file?.data.attributes?.uri?.url} type="audio/mpeg" />
                        </audio>
                    </PaddedBox>
                }

                {field_transcript && field_transcript.data?.relationships?.field_media_document?.data.attributes?.uri?.url &&
                    <PaddedBox>
                        <Link 
                            to={{ pathname: `${field_transcript.data.relationships.field_media_document.data.attributes.uri.url}` }}
                            target="_blank" 
                            rel="noopener noreferrer">
                            Download Transcript
                        </Link>
                    </PaddedBox>
                }
            </>
        );
    };

    return (
        <Container> 
            {field_image.data && field_image.data.relationships.field_media_image.data ?
                (
                    <TwoColumn variant='wide-right'>
                        <Box margin="auto" center={true}>
                            <img width="200px" src={field_image.data.relationships.field_media_image.data.attributes.uri.url} />
                        </Box>
                        <Box>
                            {recordingContent()}
                        </Box>
                    </TwoColumn>
                ) : (
                    <Box>
                        {recordingContent()}
                    </Box>
                )
            }
        </Container>
    );
};

export default AudioRecording;
