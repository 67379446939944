import React from 'react';
import { withRouter } from 'react-router';
import { FlexBox, PageHeadline } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import { JourneylineHeader } from 'dma-ui-kit';
import { ImageDetector } from 'react-image-detect';

const ImageDetectPage = (props) => {

    let endpoint = "https://imagedetect.dma.org";
    switch(process.env.REACT_APP_ENV) {
        case 'staging':
            endpoint = "https://imagedetectstaging.dma.org";
            break;
        case 'development':
            endpoint = "http://imagedetectstaging.dma.org";
            break;
    }

    const { history } = props;

    const handleImageDetect = (objectID) => {
        history.push(`/art/collection/object/${objectID}`);
    }

    return (
        <>
            <JourneylineHeader />
            <FlexBox justifyContent='center' alignItems='center'>
                <PageHeadline 
                    headline={translateInterfaceText('Art Detection')}
                    caption={translateInterfaceText('Identify works of art using your mobile device.')}
                    align={"center"} 
                />
            </FlexBox>
            <FlexBox justifyContent='center' alignItems='center'>
                <ImageDetector endpoint={endpoint} action={handleImageDetect} />
            </FlexBox>
        </>
    );
};

export default withRouter(ImageDetectPage);
