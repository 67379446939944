import { React, useContext } from 'react';
import { SiteContext } from 'site/SiteContext';
import { Body, Box, H4 } from 'dma-ui-kit';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const NewsFooter = () => {
    const { siteData } = useContext(SiteContext);
    const { news_footer } = siteData;

    return (
        <Container>
            <H4>{news_footer.title}</H4>
            <Body><span dangerouslySetInnerHTML={{__html: news_footer.body}} /></Body>
        </Container>
    );
};

export default NewsFooter;