import React from 'react';
import { PageHeadline } from 'dma-ui-kit';

export default (props) => {
  const { attributes, relationships } = props.paragraph;
  const { field_caption_text: caption, field_header_text: headline } = attributes;
  const align = (relationships.field_alignment?.data?.attributes?.name) ? relationships.field_alignment.data.attributes.name : 'left';

  return (
    <>
      <div style={{ alignItems:align, display: 'flex', justifyContent:align, top: '30px' }}>
        <PageHeadline headline={headline} caption={caption} align={align} />
      </div>
    </>
  );
};
