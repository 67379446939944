import React from 'react';
import { buildIIIFImageURL } from 'helpers/collection';
import { Image } from 'dma-ui-kit';

const CollectionImage = props => {
    const { paragraph } = props;

    const idObject = paragraph.attributes.field_collection_object;
    const idImage = paragraph.attributes.field_collection_image;
    
    return (
        <>
            <Image
                alt="Alt text here"
                style={{ cursor: 'pointer' }}
                src={`${buildIIIFImageURL(idObject, idImage, { size: 200, type: 'square' } )}`}
            />
        </>
    );
};

export default CollectionImage;