import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { Body, Box, Button } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const NewsletterForm = props => {
    const { field_error_page, field_sfmc_member_id, field_success_page } = props.paragraph.attributes;
    const { field_newsletters } = props.paragraph.relationships;

    const baseLocation = window.location.protocol + "//" + window.location.hostname;

    const errorLink = baseLocation + field_error_page.uri.replace('internal:', '');
    const successLink = baseLocation + field_success_page.uri.replace('internal:', '');

    const submitLabel = translateInterfaceText("Submit");

    const children = generateChildrenFromList(field_newsletters.data);

    return (
        <Container>
            <form action="https://cl.s11.exct.net/DEManager.aspx" name="subscribeForm" method="post">
                <input type="hidden" name="_clientID" value={field_sfmc_member_id} />
                <input type="hidden" name="_deExternalKey" value="NewsletterSubmissions" />
                <input type="hidden" name="_action" value="add" />
                <input type="hidden" name="_returnXML" value="0" />
                <input type="hidden" name="_successURL" value={successLink} />
                <input type="hidden" name="_errorURL" value={errorLink} />

                <input type="hidden" name="Email Type" value="HTML" />

                <Box mb="10px">
                    <Body>Name: <input type="text" name="FullName" /></Body>
                </Box>

                <Box mb="20px">
                    <Body>Email: <input type="text" name="EmailAddress" /></Body>
                </Box>

                <Box ml="40px">
                    {children}
                </Box>

                <Box ml="40px" mt="20px" mb="20px">
                    <Body><input type="radio" name="Action" value="Subscribe" checked /> Subscribe</Body>
                    <Body><input type="radio" name="Action" value="Unsubscribe" /> Unsubscribe</Body>
                    <Body><input type="radio" name="Action" value="UnsubscribeAll" /> Unsubscribe All</Body>
                </Box>

                <Box><Button type="submit" variant="tertiary" label={submitLabel} /></Box>
               
            </form>
        </Container>
    );
};

export default NewsletterForm; 