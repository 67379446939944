import { QuoteBoxImage } from 'dma-ui-kit';

const QuoteBoxImageParagraph = (props) => {
    const { attributes, relationships } = props.paragraph;

    const variant = (attributes.field_variant_right) ? 'right' : 'left';
    const imgSrc = relationships.field_media.data[0].relationships.field_media_image.data.attributes.uri.url;

    return (
        <>
            <QuoteBoxImage
                image={imgSrc}
                variant={variant}
            />
        </>
    );
};

export default QuoteBoxImageParagraph;