import { useContext } from "react";
import SiteContext from "site/SiteContext";
import { get } from 'lodash';
import { CollectionFooter } from 'dma-ui-kit';


const CollectionDisclaimer = () => {
    const { siteData } = useContext(SiteContext);
    const collection_disclaimer = get(siteData, 'collection_disclaimer', []);
    const { title, body } = collection_disclaimer;

    return (
        <CollectionFooter title={title} description={body} />
    );
};

export default CollectionDisclaimer;
