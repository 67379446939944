import { Body, Box } from 'dma-ui-kit';
import styled from 'styled-components';
import { generateChildrenFromList } from "cms/parsers";


const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const SponsorBlock = props => {
    const sponsorGroups = generateChildrenFromList(props.paragraph.relationships.field_sponsor_groups.data);
    
    return (
        <Container>
            <Body>
                {sponsorGroups}
            </Body>
        </Container>
    );
};

export default SponsorBlock;
