import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { HeroStack } from 'dma-ui-kit';

const Stack = ({ paragraph }) => {
  const { attributes, relationships } = paragraph;

  const children = generateChildrenFromList(relationships.field_hero_stack_items.data);

  return (
    <HeroStack>
      {children}
    </HeroStack>
  );
};

export default Stack;
