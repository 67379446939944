import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import SiteContext from 'site/SiteContext';
import { Body, Box } from 'dma-ui-kit';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 10px;
display: block;
`;

const LinkContainer = styled(Box)`
display: flex;    
padding-left: 20px;
margin-bottom: 10px;
`;

const RecentNews = (props) => {
    const { attributes, relationships } = props.paragraph;

    const { data } = relationships.field_news_category;
    const limit = attributes.field_display_limit ? attributes.field_display_limit : 10;
    const [categoryItems, setCategoryItems] = useState([]);

    const showNewsItems = () => {
        const results = categoryItems.map((result, index) => {
            return (
                <LinkContainer key={index}>
                    <Link to={{ pathname: `${result.url[0]}` }}>
                        <span>
                            <Body variant='bold'>{result.title[0]}</Body>
                            <Body variant='small'>
                                {formatInTimeZone(fromUnixTime(result.field_date[0]), 'America/Chicago', 'MMM do yyyy')}
                            </Body>
                        </span>
                    </Link>
                </LinkContainer>
            );
        });
        return results;
    };

    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const getCategoryItems = async (categoryList) => {
        const query = { "query": { "bool": { "must": [ { "query_string" : { "query": `${categoryList}`, "fields": ["field_category"]} } ]}}, "sort": [ { "field_date": { "order" : "desc" }}], "size": `${limit}` };
        const res = await axios.post(searchEndpoint, query);

        if (getNews)
        {
            const hits = res.data.hits.hits.map(hit => ({ ...hit._source }));
            setCategoryItems(hits);
        }
    };

    const categories = data.map((item, index) => { return item.attributes.drupal_internal__tid; });
    const categoryList = categories.join(" OR ");

    let getNews = true;

    useEffect(() => {
        getCategoryItems(categoryList);

        return () => {
            getNews = false;
        };
    }, [getNews, categoryList]);
    
    return (
        <Container>
            {showNewsItems()}
        </Container>
    );
};

export default withRouter(RecentNews);