import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';
import DrupalPage from 'components/pages/drupal/DrupalPage';
import PageNotFoundPage from 'components/pages/PageNotFound';
import ExternalRedirectInterstiatial from 'components/pages/ExternalRedirectInterstitial';
import { SiteContext } from 'site/SiteContext';

// see https://reactrouter.com/web/example/route-config
// https://stackoverflow.com/questions/43026690/declaring-react-routes-in-a-separate-file-and-importing/46659102
const ExternalRedirect = ({ to, ...routeProps }) => {
    return <Route {...routeProps} render={() => {
        window.location = to;
        return (
            <ExternalRedirectInterstiatial />
            );
    }} />;
};

export const RedirectRoutes = (props) => {
    let dynamicRoutes = [];
    const { siteData } = useContext(SiteContext);

    // Dynamic redirects to external sites i.e. promotions
    if (get(siteData, 'redirects')) {
        // Adding `key` prop to address https://reactjs.org/docs/lists-and-keys.html#keys
        dynamicRoutes.push(...siteData.redirects.map((config, ind) => <ExternalRedirect key={ind} exact path={`/${config.pattern}`} to={config.target} />));
    }

    // Let the Drupal handler deal with any other requests
    dynamicRoutes.push(<Route key={'http-404'} component={(props) => (siteData) ? <DrupalPage {...props} /> : <></>} />);

    // Append 404 route here, it won't get picked up if in the App.js file after these dynamic routes.
    // dynamicRoutes.push(<Route key={'http-404'} component={() => (siteData && siteData.aliases) ? <PageNotFoundPage /> : <></>} />);

    return (
        <Switch>
            {dynamicRoutes}
        </Switch>
    );
};

