import fp from 'lodash/fp';

export const baseQuery = () => ({
  query: {
    bool: {
      must: [],
    },
  },
});

export const addPagination = (page, size = 20) => input => {
  if(!page && !size) return input;
  const nextOut = {
    ...input,
    from: (page - 1) * size || 0,
    size: size,
  };
  return nextOut;
};

export const addTerm = (type, term) => input => {
  if(!term) return input;
  const nextOut = input;

  switch (type)
  {
    case "collection":
      nextOut.query.bool.must.push({
        bool: { should: [
          {query_string: { query: `${term || ''}`, fields: ["id", "number", "credit_line", "culture"] }},
          {query_string: { query: `*${term || ''}*`, fields: ["alt_title", "title", "constituents.alt_name", "constituents.name", "constituents.sort"] }}
        ]}
      });
      break;
    default:
      nextOut.query.bool.must.push({
        query_string: { query: `*${term || ''}*` },
      });
      break;
  }
  return nextOut;
};

export const addType = type => input => {
  if(!type) return input;
  const nextOut = input;
  switch(type) {
    case 'event':
    case 'relatedEvent':
    case 'upcomingEvent':
      nextOut.query.bool.must.push(
        { query_string : { query: "true", fields: ['status']}},
        { query_string : { query: 'event', fields: ['type']} }
      );
      break;
    case 'exhibition':
      nextOut.query.bool.must.push(
        { query_string : { query: "true", fields: ['status']}},
        { query_string : { query: "exhibitions", fields: ['type']} },
        );
      break;
    case 'news':
      nextOut.query.bool.must.push(
        { query_string : { query: "true", fields: ['status']}},
        { query_string : { query: type, fields: ['type']} }
      );
      break;
    case 'past_exhibition':
      nextOut.query.bool.must.push(
        { query_string : { query: "true", fields: ['status']}},
        { query_string : { query: "past_exhibition", fields: ['type']} }
      );
      break;
  }
  return nextOut;
};

export const addTags = tags => input => {
  if(!tags) return input;
  const nextOut = input;
  return nextOut;
};

export const addOrder = (type, order) => input => {
  if(!type) return input;

  const nextOut = {
    ...input,
    sort: []
  };
  
  switch(type) {
    case 'collection':
      break;
    case 'event':
      nextOut.sort.push(
        { date_rrule_end: { order : order || "asc", mode : "min" }}
      );
      break;
    case 'exhibition':
      nextOut.sort.push(
        { field_start_date: { order : order || "asc" }}
      );
      break;
    case 'past_exhibition':
      nextOut.sort.push(
        { field_start_date: { order : order || "desc" }}
      );
      break;
    case 'news':
      nextOut.sort.push(
        { field_date: { order : order || "desc" }}
      );
      break;
    case 'relatedEvent':
      nextOut.sort.push(
        { title: { order : order || "asc" }}
      );
      break;
    case 'upcomingEvent':
      nextOut.sort.push(
        { field_date_rrule: { order : order || "asc" }}
      );
      break;
  }

  return nextOut;
};

export const addFilters = (type, filters) => input => {
  if(!filters) return input;
  const nextOut = input;
  Object.keys(filters).forEach(filter => {
    const filterValues = filters[filter];

    switch(type) {
      case 'collection':
        switch (filter) {
          case "artist":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues}`, "fields": ["constituents.name.keyword"]} },
                { "query_string" : { "query": `Artist`, "fields": ["constituents.role.keyword"]} }
              );
            break;
          case "credit_line":
            if (filterValues[0])
                nextOut.query.bool.must.push(
                  { "query_string" : { "query": `${filterValues}`, "fields": ["credit_line"]} }
                );
            break;
          case "culture":
            if (filterValues[0])
                nextOut.query.bool.must.push(
                  { "query_string" : { "query": `${filterValues}`, "fields": ["culture.keyword"]} }
                );
            break;
          case "department":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues}`, "fields": ["department.keyword"]} }
              );
            break;
          case "medium":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues}`, "fields": ["medium.keyword"]} }
              );
            break;
          case "onview":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "exists" : { "field": "location" } }
              );
            break;
          case "credit":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues}`, "fields": ["credit_line.keyword"]} }
              );
            break;
          case "package":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues}`, "fields": ["packages.keyword"]} }
              );
            break;
          case "term":
            if (filterValues[0])
              if (filterValues.length > 1)
              {
                let values = filterValues.map((value) => ({"query_string" : { "query": `${value}`, "fields": ["terms"]} }));
                nextOut.query.bool.must.push(
                  { bool: { should: values }}
                );
              }
              else
                nextOut.query.bool.must.push(
                  { "query_string" : { "query": `${filterValues}`, "fields": ["terms"]} }
                );
            break;
          case "title":
            if (filterValues[0])
              if (filterValues.length > 1)
              {
                let values = filterValues.map((value) => ({"query_string" : { "query": `*${value}*`, "fields": ["title"]} }));
                nextOut.query.bool.must.push(
                  { bool: { should: values }}
                );
              }
              else
              nextOut.query.bool.must.push(
                  { "query_string" : { "query": `*${filterValues}*`, "fields": ["title"]} }
                );
            break;
          }

        break;
  
      case "event":
        switch (filter) {
          case "dateRange":
            if (filterValues[0] && filterValues[1])
              nextOut.query.bool.must.push(
                { "range" : {"field_date_rrule" : { "gte" : `${filterValues[0]}` }}}, 
                { "range" : {"field_date_rrule" : { "lte" : `${filterValues[1]}` }}}
              );
            break;          
          case "audiences":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues[0]}`, "fields": ["audience_name"]} }
              );
            break;
          case "category":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues[0]}`, "fields": ["event_category_name"]} }
              );
            break;
          case "location":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues[0]}`, "fields": ["location_name"]} }
              );
            break;
        }
        break; 

        case 'exhibition':
          switch (filter) {
            case "status":
              const now = Math.floor(new Date().getTime() / 1000);

              if (filterValues[0])
                switch(filterValues[0])
                {
                  case "current":
                    nextOut.query.bool.must.push({ "range" : {"field_start_date" : { "lte" : `${now}` }}});
                    nextOut.query.bool.must.push({ "range" : {"field_end_date" : { "gte" : `${now}` }}});
                    break;
                  case "upcoming":
                    nextOut.query.bool.must.push({ "range" : {"field_start_date" : { "gte" : `${now}` }}});
                    break;
                  case "past":
                    nextOut.query.bool.must.push({ "range" : {"field_end_date" : { "lte" : `${now}` }}});
                    break;
                }
              break;          
          }
          break;

        case 'past_exhibition':
          switch (filter) {
          case "dateRange":
            if (filterValues[0] && filterValues[1])
            {
              const startDate = new Date(filterValues[0], 0, 1) / 1000;
              const endDate = new Date(filterValues[1], 12, 0) / 1000;

              nextOut.query.bool.must.push(
                { bool: {
                  should: [
                    { bool : { must: [
                        { "range" : {"field_start_date" : { "gte" : `${startDate}` }}}, 
                        { "range" : {"fiezld_start_date" : { "lte" : `${endDate}` }}}
                    ]}},
                    { bool : { must: [
                        { "range" : {"field_end_date" : { "gte" : `${startDate}` }}}, 
                        { "range" : {"field_end_date" : { "lte" : `${endDate}` }}}
                    ]}}
                  ]
                }}
              );
            }
            break;          
          case "location":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues[0]}`, "fields": ["location_name"]} }
              );
            break;
          }
        break;

      case "relatedEvent":
        if (filterValues[0])
          nextOut.query.bool.must.push(
            { "query_string" : { "query": `${filterValues[0]}`, "fields": ["related_exhibition_title"]} }
          );
        break;

      case "upcomingEvent":
        switch (filter) {
          case "dateAfter":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "range" : {"field_date_rrule" : { "gte" : `${filterValues[0]}` }}}
              );
            break;          
          case "categoryid":
            if (filterValues[0])
              nextOut.query.bool.must.push(
                { "query_string" : { "query": `${filterValues[0]}`, "fields": ["field_event_category"]} }
              );
            break;
        }
        break;         
    };
  });
  return nextOut;
};

export const addAggregations = type => input => {
  if(!type) return input;
  const nextOut = input;

  switch(type) {
    case 'collection':
      nextOut.aggs = {};
      nextOut.aggs.culture = { "terms": { "field": "culture.keyword", "size": 200 } };
      nextOut.aggs.department = { "terms": { "field": "department.keyword", "size": 200 } };
      nextOut.aggs.medium = { "terms": { "field": "medium.keyword", "size": 200 } };
      nextOut.aggs.artist = { "filter": { "bool": { "must_not": [ { "term": { "constituents.role.keyword": "Depicted individual" }}, { "term": { "constituents.role.keyword": "Importer" }}, { "term": { "constituents.role.keyword": "Exporter" }} ]}}, "aggs": { "name": { "terms" : { "field": "constituents.name.keyword", "size": 200 } } }};
      nextOut.aggs.onview = { "filter": { "bool": { "must": [ { "exists": { "field": "location" }}]}}};
      break;

    case 'event':
      nextOut.aggs = {};

      nextOut.aggs.audience = { "terms": { "field": "audience_name", "size": 25 } };
      nextOut.aggs.category = { "terms": { "field": "event_category_name", "size": 25 } };
      nextOut.aggs.location = { "terms": { "field": "location_name", "size": 25 } };
      break;
      
    case 'exhibition':
      nextOut.aggs = {};
      nextOut.aggs.location = { "terms": { "field": "location_name", "size": 25 } };
      break;
    }
  return nextOut;
};

export const buildQuery = ({ filters, limit, order, page, tags, term, type }) => (
  fp.flow(
    addTerm(type, term),
    addType(type),
    addPagination(page, limit),
    addTags(tags),
    addOrder(type, order),
    addFilters(type, filters),
    addAggregations(type),
  )(baseQuery())
);

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setFilters':
      return { ...state, filters: action.payload };
      break;
    case 'setTerm':
      return { ...state, term: action.payload };
      break;
    case 'setPagination':
      return { ...state, ...action.payload };
      break;
    case 'setType':
      return { ...state, type: action.payload };
      break;
    case 'setTags':
      return { ...state, tags: action.payload };
      break;
    case 'setOrder':
      return { ...state, order: action.payload };
      break;
  };
};

