import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { FlexBox, H4, H6, SlideShow, grey800 } from 'dma-ui-kit';
import styled from 'styled-components';

const CollectionTitle = styled(FlexBox)`
    background-color: ${grey800};
    padding-top: 1em;
    margin-top: 2.5em;
`;


const CollectionList = props => {
    const { paragraph } = props;

    const title = paragraph.attributes.field_title;
    const subtitle = paragraph.attributes.field_subtitle;

    const children = generateChildrenFromList(paragraph.relationships.field_collections.data);

    return (
        <>
            <CollectionTitle justifyContent="center" alignItems="center">
                <H4>{title}</H4>
            </CollectionTitle>
            {subtitle && <H6>{subtitle}</H6>}
            <SlideShow
                color={grey800}
            >
                {children}
            </SlideShow>
        </>
    );
};

export default CollectionList;