import { JourneylineHeader } from 'dma-ui-kit';

export const buildJourneylineHeader = (pageManifest) => {

    if (pageManifest !== null) {

        const hero_image = pageManifest.relationships && pageManifest.relationships.field_hero_image?.data ? 
            pageManifest.relationships.field_hero_image.data.relationships.field_media_image.data.attributes.uri.url : 
            undefined;

        const hero_image_mobile = pageManifest.relationships && pageManifest.relationships.field_hero_image_mobile?.data ? 
            pageManifest.relationships.field_hero_image_mobile.data.relationships.field_media_image.data.attributes.uri.url : 
            hero_image;

        const hero_image_tablet = pageManifest.relationships && pageManifest.relationships.field_hero_image_tablet?.data ? 
            pageManifest.relationships.field_hero_image_tablet.data.relationships.field_media_image.data.attributes.uri.url : 
            hero_image;

        const hero_text = pageManifest.attributes && pageManifest.attributes.field_hero_text ? 
            pageManifest.attributes.field_hero_text : 
            undefined;

        return ( hero_image && 
            <JourneylineHeader desktopSrc={hero_image} mobileSrc={hero_image_mobile} tabletSrc={hero_image_tablet} title={hero_text} /> 
        );
    }

    return '';
};

