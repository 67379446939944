import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { translateInterfaceText } from 'helpers/translation';
import { ButtonInput } from 'dma-ui-kit';

const CollectionSearchBar = props => {
    const { history } = props;
    const [searchTerm, setSearchTerm] = useState('');

    const placeholder = translateInterfaceText('Search the collection');
    const label = translateInterfaceText('Search');
    const setSearch = (e) => { setSearchTerm(e.target.value); };
    const search = () => { history.push(`/art/collection/search?s=${searchTerm}`); };

    return (
        <>
            <div>
                <div style={{ display: "block", width: '50%', margin: 'auto' }}>
                    <ButtonInput 
                        placeholder={placeholder}
                        label={label}
                        onChange={setSearch}
                        onClick={search}
                    />
                </div>
            </div>
        </>
    );
};

export default withRouter(CollectionSearchBar);