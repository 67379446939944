import { useContext } from "react";
import SiteContext from "site/SiteContext";

const envir = process.env.REACT_APP_COLLECTION_ENV;
const baseImageURL = process.env.REACT_APP_IMAGE_BASE_PATH;
const baseCollectionURL = process.env.REACT_APP_COLLECTION_BASE_PATH;

// Maybe use imageOptions instead of imageSize/type etc.
export const buildIIIFImageURL = (objectID, imageID, imageOptions={}) => {

    // second half needs rework    
    const size = imageOptions.size == 'full' ? 'full' : imageOptions.size + ',';
    const type = imageOptions.type ? imageOptions.type : 'full';

    return `${baseImageURL}${envir}__objects__${objectID}__${imageID}__image.jpg/${type}/${size}/0/default.jpg`;
};

export const buildIIIFInfoURL = (objectID, imageID) => {
    return `${baseImageURL}${envir}__objects__${objectID}__${imageID}__pyramid.jp2/info.json`;
};

export const buildObjectManifestURL = ({ objectID }) => {
    const { languagePath } = useContext(SiteContext);

    // Hacky work-around to gardener not placing english content in a en folder
    // will fix this
    const lp = (languagePath == '/en') ? '' : languagePath;

    return `${baseCollectionURL}${envir}/objects/${objectID}${lp}/object.json`;
};

export const buildIIIFManifestURL = ( objectID ) => {
    const { languagePath } = useContext(SiteContext);

    // Hacky work-around to gardener not placing english content in a en folder
    // will fix this
    const lp = (languagePath == '/en') ? '' : languagePath;

    return `${baseCollectionURL}${envir}/objects/${objectID}${lp}/manifest.json`;
};