import React from 'react';
import useScript from 'helpers/useScript';

const IssuuEmbed = (props) => {
    useScript('https://e.issuu.com/embed.js');

    const { field_config_id, field_width, field_height } = props.paragraph.attributes;

    return (
        <>
            <div>
                <div class="issuuembed" data-configid={field_config_id} style={{width: field_width + 'px', height: field_height + 'px', margin: 'auto'}}>&nbsp;</div>
            </div>
        </>
    );
};

export default IssuuEmbed;