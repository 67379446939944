import React from 'react';
import { FlexBox, Loading } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import { JourneylineHeader } from 'dma-ui-kit';

const DefaultPage = () => {
    return (
        <>
            <JourneylineHeader />
            <FlexBox justifyContent='center' alignItems='center'>
                <Loading />
            </FlexBox>
        </>
    );
};

export default DefaultPage;
