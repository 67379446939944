import React from 'react';
import { CarouselItem } from 'dma-ui-kit';
import { get } from 'lodash';

const Item = ({ paragraph }) => {
  const { attributes, relationships } = paragraph;
  const { 
    field_slide_title: title,
    field_slide_eyebrow: tag,
  } = attributes;

  const { url: image } = get(
    relationships,
    'field_slide_image.data.relationships.field_media_image.data.attributes.uri',
    '',
  );

  return <CarouselItem image={image} tag={tag} title={title} />;
};

export default Item;
