import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';
import { Body, Box, Button, H6, red, SocialShare, TwoColumn } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
  padding: 0 0 0 12px;
  @media (min-width: 1025px) {
      padding: 0 0 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 0 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const ExhibitionPage = ({ pageManifest }) => {

    const { field_additional_info,
            field_end_date, 
            field_member_purchase_ticket_url,
            field_members_cost,
            field_members_only,
            field_purchase_ticket_url,
            field_regular_cost,
            field_start_date,
            title
    } = pageManifest.attributes;

    const { field_featured_image,
            field_location,
            field_paragraph
    } = pageManifest.relationships;

    const children = generatePageParagraphs(field_paragraph.data, title);

    const getTickets = translateInterfaceText('Get Tickets');

    return (
        <Container> 
            <TwoColumn variant='xl-left'>
                <div>
                    {children}
                </div>

                <div>
                    <Box mb={'10px'}>
                        <H6>{translateInterfaceText('Dates')}</H6>
                        {field_start_date && <Body variant="small">{formatInTimeZone(Date.parse(field_start_date), 'UTC', 'MMM do, yyyy')}{field_end_date && ' to'}</Body>}
                        {field_end_date && <Body variant="small">{formatInTimeZone(Date.parse(field_end_date), 'UTC', 'MMM do, yyyy')}</Body>}
                    </Box>

                    {field_location.data && field_location.data.attributes &&
                        <Box mb={'10px'}>
                            <H6>{translateInterfaceText('Location')}</H6>
                            <Body variant="small">{field_location.data?.attributes?.name}</Body>
                        </Box>
                    }

                    <Box mb={'10px'}>
                        <Box mb={'5px'}>
                            <H6>{translateInterfaceText('Ticket Info')}</H6>
                        </Box>

                        <Box mb={'10px'}>
                            <Body variant="small" bold={true}>{translateInterfaceText('Member Tickets')}</Body>
                            <Body variant="small">{(field_members_cost && field_members_cost != 0) ? `$${field_members_cost}` : translateInterfaceText('Free')}</Body>
                            {field_member_purchase_ticket_url &&
                                <Box mt={'10px'}>
                                    <Link 
                                    to={{ pathname: field_member_purchase_ticket_url.uri }}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                        <Button 
                                            label={getTickets}
                                            variant={'outline'}
                                            compact={true}
                                            color={red}
                                        />
                                    </Link>
                                </Box>
                            }
                        </Box>

                        {(field_members_only == 0) && 
                        <Box mb={'10px'}>
                            <Body variant="small" bold={true}>{translateInterfaceText('Public Tickets')}</Body>
                            <Body variant="small">{(field_regular_cost && field_regular_cost != 0) ? `$${field_regular_cost}` : translateInterfaceText('Free')}</Body>
                            {field_purchase_ticket_url &&
                                <Box mt={'10px'}>
                                    <Link 
                                        to={{ pathname: field_purchase_ticket_url.uri }}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <Button 
                                            label={getTickets}
                                            variant={'outline'}
                                            compact={true}
                                            color={red}
                                        />
                                    </Link>
                                </Box>
                            }
                        </Box>
                        }
                    </Box>

                    {field_additional_info && 
                        <Box mb={'10px'}>
                            <H6>{translateInterfaceText('Additional Info')}</H6>
                            <Body variant="small">{field_additional_info}</Body>
                        </Box>
                    }
                    
                    <Box>
                        <H6>{translateInterfaceText('Share This')}</H6>
                        <SocialShare
                            title={title}
                            media={field_featured_image?.data?.relationships?.field_media_image?.data?.attributes?.uri?.url}
                            color={red} 
                        />
                    </Box>
                </div>
            </TwoColumn>
        </Container>
    );
};

export default ExhibitionPage;
