import { FlexBox } from 'dma-ui-kit';
import styled from 'styled-components';

const Image = styled.img`
    max-width: 220px;
    max-height: 100px;
    margin: auto;
`;

const Sponsor = props => {
    const { attributes, relationships } = props.paragraph;

    const title = attributes.field_title;
    const url = attributes.field_url;
    const src = relationships.field_media.data[0].relationships.field_media_image.data.attributes.uri.url;

    return (
        <FlexBox w="220px" minHeight="50px" maxHeight="100px" mr="10px">
            <a href={url} target={'_blank'}>
                <Image src={src} alt={title} />
            </a>
        </FlexBox>
    );
};

export default Sponsor;
