import { Body, Box } from 'dma-ui-kit';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;
const ContentText = props => {
    return (
        <Container>
            <Body>
                <span dangerouslySetInnerHTML={{__html: props.paragraph.attributes.field_text.processed}} />
            </Body>
        </Container>
    );
};

export default ContentText;
