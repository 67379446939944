import { React } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { translateInterfaceText } from 'helpers/translation';
import { Box, H5, Body } from 'dma-ui-kit';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const NewsContactBlock = (props) => {
    const { attributes } = props.paragraph;

    const resourceDownloads = () => {
        let results = (<></>);

        results = attributes.field_url?.map(result => {
            return(
                <Body><Link to={{ pathname: result.uri }}>{result.title}</Link></Body>
            );
        });

        return results;
    };

    return (
        <Container>
            <H5>{translateInterfaceText('Press Inquiries')}</H5>

            <Body>{attributes.field_name}</Body>
            <Body>{attributes.field_title}</Body>
            <Body>{attributes.field_phone_number}</Body>
            <Body>{attributes.field_email}</Body>

            {attributes.field_url && 
                <>
                    <H5>{translateInterfaceText('Resources for Download')}</H5>
                    {resourceDownloads()}
                </>
            }   
        </Container>
    );
};

export default withRouter(NewsContactBlock);