import React from 'react';
import { FlexBox, PageHeadline } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import { JourneylineHeader } from 'dma-ui-kit';

const PageNotFoundPage = () => {
    return (
        <>
            <JourneylineHeader />
            <FlexBox justifyContent='center' alignItems='center'>
                <PageHeadline 
                    headline={translateInterfaceText('Page not found')}
                    caption={translateInterfaceText('The page you attempted to load cannot be found.')}
                    align={"center"} 
                />
            </FlexBox>
        </>
    );
};

export default PageNotFoundPage;
