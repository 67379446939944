import React from 'react';
import { Body, Box } from 'dma-ui-kit';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const PressItemContainer = styled(Box)`
margin-bottom: 10px;
`;


const PressItem = props => {
    const { attributes } = props.paragraph;

    const { field_date, field_news_source, field_press_link } = attributes;

    return (
        <PressItemContainer>
            <Body>{field_news_source}</Body>
            <Link 
                to={{ pathname: field_press_link.uri}}
                target="_blank" 
                rel="noopener noreferrer">
                {field_press_link.title}
            </Link>
            <Body>{field_date}</Body>
        </PressItemContainer>
    );
};

export default withRouter(PressItem);