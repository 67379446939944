import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Body, Box, ButtonInput, FlexBox, Pagination, SiteSearchResult } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { SearchableSection } from 'search/SearchableSection';
import { buildQuery, reducer as queryReducer } from 'search/queries';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }    

  margin-bottom: 10px;
  display: block;
`;

const StickySearch = styled(FlexBox)`
  background-color: white;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  margin-bottom: 24px;
  top: -1px;
  width: 100vw;
`;

const PaginationContainer = styled(Box)`
  max-width: 320px;
  margin: auto;

  @media (min-width: 1025px) {
      max-width: 800px;
  }
  @media (max-width: 1024px) {
      max-width: 640px;
  }    
`;

const NewsSearch = React.memo(() => {
  const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
  const envir = process.env.REACT_APP_ENV;
  const { searchPath } = useContext(SiteContext);
  const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;
  const history = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const initialTerm = params.get('s');
  const initialPage = params.get('p');

  const [query, setQuery] = useState(null);
  const [nextTerm, setNextTerm] = useState(null);

  const [options, dispatch] = useReducer(
    queryReducer,
    {  
      type: 'news',
      page: initialPage || 1,
      term: initialTerm || '',
      limit: 20,
      filters: {}
    },  
  );

  useEffect(() => {
    const nextQuery = buildQuery(options);
    setQuery(nextQuery);
  }, [buildQuery, setQuery, options]);

  const handleTerm = e => setNextTerm(e.target.value);

  const handleTermChange = () => {
    dispatch({ type: 'setTerm', payload: nextTerm });
    dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
    history.push(`/press-room?s=${nextTerm}&p=1`);
    window.scrollTo(0, 0);
  };

  const handlePageChange = val => {
    dispatch({ type: 'setPagination', payload: { page: val, limit: options.limit }});
    history.push(`/press-room?s=${options.term}&p=${val}`);
    window.scrollTo(0, 0);
  };

  const labels = {
    search: translateInterfaceText('Search'),
    enterSearchTerm: translateInterfaceText('Enter search term'),
    loading: translateInterfaceText('Loading...'),
    readMore: translateInterfaceText('Read more'),
    resultsFound: translateInterfaceText('results found for')
  };

  const ResultsMessage = ({ term, hits, count, loading }) => {
    let message = labels.enterSearchTerm;
    if (term) {
      if (loading) {
        message = labels.loading;
      } else {
        if (term && hits?.length > 0) {
          message = `${count} ${labels.resultsFound} "${options.term}"`;
        } else {
          message = `0 ${labels.resultsFound} "${options.term}"`;
        }
      }
    }
    return <Body textAlign="center">{message}</Body>;
  };

  return (
    <Container>
      <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/press-room"}>
        {({ results, loading }) => {
          
          return (
            <>
              <StickySearch>
                <Box w="50%">
                  <ButtonInput
                    label={labels.search}
                    placeholder={options?.term ? options.term : labels.enterSearchTerm}
                    onChange={handleTerm}
                    onClick={handleTermChange}
                  />
                </Box>
                {options.term && results?.hits?.length > 0 && 
                  <ResultsMessage
                    loading={loading}
                    hits={results.hits}
                    count={results.resultCount}
                    term={options.term}
                  />
                }
              </StickySearch>
              {options.term && results?.hits?.length > 0 && 
                <>
                  <div style={{ display: "block", width: '80%', margin: 'auto' }}>
                    <div>
                      {results.hits.map((result, index) => (
                            <SiteSearchResult
                              key={`search-result-${index}`}
                              type={''}
                              title={result.title[0]}
                              body={result.processed && result.processed[0] ? result.processed[0] : ''}
                              action={() => history.push(result.url[0])}
                              actionLabel={labels.readMore}
                            />
                      ))}
                    </div>
                  </div>
                  <PaginationContainer>
                    <Pagination
                      currentPage={options.page}
                      pageCount={results.pageCount}
                      onChange={handlePageChange}
                    />
                  </PaginationContainer>            
                </>
              }
            </>
          );
        }}
      </SearchableSection>
    </Container>
  );
});

export default NewsSearch;
