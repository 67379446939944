import React, { useContext, useState, useEffect, useReducer } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Body, Box, Card, FilterBar, Filter, Pagination, SearchResultsGrid } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { filterQuery, history, unpackFilterQuery } from 'helpers/search';
import { SearchableSection } from 'search/SearchableSection';
import ImageNotFound from 'assets/resultmissing.png';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
    padding: 0 12px;

    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }    

    margin-bottom: 10px;
    display: block;
`;

const PaginationContainer = styled(Box)`
    max-width: 320px;
    margin: auto;
    margin-top: 48px;

    @media (min-width: 1025px) {
        max-width: 800px;
    }
    @media (max-width: 1024px) {
        max-width: 640px;
    }    
`;

const PastExhibitionSearch = React.memo(() => {
    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const noResultsText = translateInterfaceText("No Results Found");
    const loadingText = translateInterfaceText("Loading");

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const date = new Date();

    const initialTerm = params.get('s');
    const initialFilter = unpackFilterQuery(params.get('f'));
    const initialPage = params.get('p');

    const defaultFilter = { dateRange: [1905, date.getFullYear()] };

    const labels = {
      location: translateInterfaceText('Location'),
      years: translateInterfaceText('Years')
    };

    const [query, setQuery] = useState(null);

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'past_exhibition',
        page: initialPage || 1,
        term: initialTerm || '',
        limit: 9,
        filters: initialFilter || defaultFilter
       },  
    );

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);
  
    const handleRangeChange = val => {
      const payload = { dateRange: val };
      dispatch({ type: 'setFilters', payload: payload});
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/past-exhibitions?s=${options.term}&f=${filterQuery(payload)}&p=1`);
    };

    const handleTermChange = val => {
      dispatch({ type: 'setTerm', payload: val });
      dispatch({ type: 'setPagination', payload: { page: 1, limit: options.limit }});
      history.push(`/art/past-exhibitions?s=${val}&f=${filterQuery(options.filters)}&p=1`);
    };

    const handlePageChange = val => {
      history.push(`/art/past-exhibitions?s=${options.term}&f=${filterQuery(options.filters)}&p=${val}`);
      dispatch({ type: 'setPagination', payload: { page: val, limit: options.limit }});
      window.scrollTo(0, 0);
    };

    const yearOptions = [];
    for (var i = 1905; i <= date.getFullYear(); i++) { yearOptions.push( {value: i, label: i} ); }

    return (
        <Container>
          <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/art/past-exhibitions"}>
            {({ results, loading }) => {
              const dateRangeDefaults = (options.filters?.dateRange) ? { from: options.filters.dateRange[0], to: options.filters.dateRange[1] } : undefined;
              return(
              <>
                <Box mb="24px">
                  <FilterBar onSearch={handleTermChange} value={options.term}> 
                    <Filter variant="range" onChange={handleRangeChange} label={labels.years} options={yearOptions} defaults={dateRangeDefaults} />
                  </FilterBar>
                </Box>
                {results?.hits && results.hits.length > 0 && !loading && (
                  <SearchResultsGrid cardsPerRow={3}>
                    {results.hits.map((result, index) => (
                      <Link key={index} to={{ pathname: result.url }}>
                        <Card 
                          title={result.title}
                          caption={(<Body variant="small">
                            {formatInTimeZone(fromUnixTime(result.field_start_date[0]), 'America/Chicago', 'MMM do, yyyy')}
                            {result.field_end_date && result.field_end_date[0] !== null && ' - '}
                            {result.field_end_date && result.field_end_date[0] !== null && formatInTimeZone(fromUnixTime(result.field_end_date[0]), 'America/Chicago', 'MMM do, yyyy')}
                          </Body>)}
                          image={result.featured_image || ImageNotFound}
                        />
                      </Link>
                    ))}
                  </SearchResultsGrid>
                )}
                {results?.hits?.length === 0 && !loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{noResultsText}</Body>
                  </Box>
                )}
                {loading && (
                  <Box w="100%" pt="48px">
                    <Body textAlign="center">{loadingText}...</Body>
                  </Box>
                )}
                <PaginationContainer>
                  <Pagination
                    currentPage={results?.currentPage || 1}
                    pageCount={results?.pageCount || 1}
                    onChange={handlePageChange}
                  />
                </PaginationContainer>
              </>
            );}}
          </SearchableSection>
        </Container>
      );
});

export default PastExhibitionSearch;
