import React from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { Box } from 'dma-ui-kit';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 10px;
display: block;
`;

const YoutubeVideo = props => {
    const { attributes, relationships } = props.paragraph;
    const opts = {
        height: '315',
        width: '560',
        playerVars: {
            autoplay: 0,
            playsinline: 0,
        },
    };

    const { field_video_id } = attributes;

    return (
        <Container>
          <YouTube videoId={field_video_id} opts={opts} />
        </Container>
    );
};

export default YoutubeVideo;