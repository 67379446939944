import React from 'react';
import { FlexBox, PageHeadline } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import { JourneylineHeader } from 'dma-ui-kit';

const ExternalRedirectInterstiatial = () => {
    return (
        <>
            <JourneylineHeader />
            <FlexBox justifyContent='center' alignItems='center'>
                <PageHeadline 
                    headline={translateInterfaceText("Please Wait")} 
                    caption={translateInterfaceText("You're now being redirected to another site.")} 
                    align={"center"} 
                />
            </FlexBox>
        </>
    );
};

export default ExternalRedirectInterstiatial;
