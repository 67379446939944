import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTop ({ children }) {
  const history = useHistory(); 
  let prevPath = history.location.pathname;

  useEffect(() => {
    const unlisten = history.listen(({pathname}) => {
      // Only scroll to top if path has changed
      if (prevPath != pathname) {
        prevPath = pathname;
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default ScrollToTop;
