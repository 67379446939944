import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'dma-ui-kit';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 10px;
display: block;
`;

const divStyle = {
    border: '0 none',
    bottom: 0,
    left: 0,
    margin: 0,
    position: 'relative',
    right: 0,
    top: 0,
    width: 'auto'
};

const iFrameStyle = {
    border: '0 none',
    bottom: 0,
    height: '1px',
    left: 0,
    margin: 0,
    minHeight: '100%',
    minWidth: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '1px'
};

const CerosEmbed = props => {
    const { attributes, relationships } = props.paragraph;
    const { field_ceros_url, field_title, field_desktop_aspect_ratio, field_tablet_aspect_ratio, field_mobile_aspect_ratio, field_padding } = attributes;

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const section = params.get('section');

    const proxyScript = document.createElement('script');
    proxyScript.src = "//view.ceros.com/scroll-proxy.min.js";
    proxyScript.async = true;
    proxyScript.defer = true;
    document.body.appendChild(proxyScript);

    const deepLinkScript = document.createElement('script');
    deepLinkScript.src = "//labs.ceros.com/sdk-plugins/deep-linking/deep-linking-plugin-v2.min.js";
    deepLinkScript.async = true;
    deepLinkScript.defer = true;
    document.body.appendChild(deepLinkScript);

    return (
        <Container>
            <div style={{ padding: '0 0 ' + `${field_padding}%`, ...divStyle }}
                id={'ceros-experience'}
                data-aspectratio={`${field_desktop_aspect_ratio}`}
                data-mobile-aspectratio={`${field_mobile_aspect_ratio}`}
                data-tablet-aspectratio={`${field_tablet_aspect_ratio}`}>
                <iframe
                    allowFullScreen
                    src={`${field_ceros_url}?section=${section}`}
                    style={iFrameStyle}
                    frameBorder='0'
                    className='ceros-experience'
                    title={field_title}
                    scrolling='no'
                />
            </div>
      </Container>
    );
};

export default CerosEmbed;
