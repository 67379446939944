import React, { useState } from 'react';
import styled from 'styled-components';
import { Body, Box, H5, red } from 'dma-ui-kit';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 12px;
display: block;
text-align: center;
`;

const ImageContainer = styled(Box)`
    margin: auto;
    width: 100%;
`;

const ImagePrimary = styled.img`
    max-height: 400px;
`;

const ImageTile = styled.img`
    max-height: 100px;
    padding: 2px;
    cursor: pointer;
`;

const SlideShow = props => {
    const { data } = props.paragraph.relationships.field_media;
    const [primaryImage, setPrimaryImage] = useState(0);

    const showPrimaryImage = () => {
        return (
            <ImagePrimary src={data[primaryImage].relationships.field_media_image.data.attributes.uri.url} alt={data[primaryImage].attributes.name} />
        );
    };

    const showTiles = () => {
        const results = data.map((result, index) => {
            const { attributes, relationships } = result;

            return (
                <ImageTile key={index} src={relationships.field_media_image.data.attributes.uri.url} alt={attributes.name} onClick={() => setPrimaryImage(index)} />
            );
        });
        return results;
    };    
    
    return (
        <Container>
            <ImageContainer>{showPrimaryImage()}</ImageContainer>
            <ImageContainer>{showTiles()}</ImageContainer>
        </Container>
    );
};

export default SlideShow;