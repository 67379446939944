// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //   identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        // domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
        // path: '/',
        // OPTIONAL - Cookie expiration in days
        // expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        // secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    //   oauth: {
    //     domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    //     scope: ['phone', 'email', 'openid'],
    //     redirectSignIn: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    //     redirectSignOut: process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    //     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    //   },
    }
};

export default awsconfig;
