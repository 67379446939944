import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { H5, red, Box } from 'dma-ui-kit';
import styled from 'styled-components';

const PressBox = styled(Box)`
    margin-top: 20px;
    width: 100%;

    padding: 0 12px;
    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }    
`;

const Press = props => {
    const { field_press_items } = props.paragraph.relationships;
    const children = generateChildrenFromList(field_press_items.data);

    return (
        <PressBox>
            <H5 color={red}>Press</H5>
            {children}
        </PressBox>
    );
};

export default Press;