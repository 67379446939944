import { generateChildrenFromList } from "cms/parsers";
import styled from 'styled-components';
import { FlexBox, H6 } from 'dma-ui-kit';

const SponsorTitle = styled(H6)`
    border-bottom: 1px solid #000;
`;

const SponsorGroup = props => {
    const { relationships, attributes } = props.paragraph;

    const title = attributes.field_title;
    const sponsors = generateChildrenFromList(relationships.field_sponsors.data);
    
    return (
        <>
            <SponsorTitle>{title}</SponsorTitle>
            <FlexBox mb="10px">
                {sponsors}
            </FlexBox>
        </>
    );
};

export default SponsorGroup;
