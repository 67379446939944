import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'dma-ui-kit';

const IndexCard = props => {
    const { paragraph } = props;
    const { field_cta_link } = paragraph.attributes;
    
    const caption = (paragraph.attributes.field_subtitle) ? paragraph.attributes.field_subtitle : '';
    const src = paragraph.relationships.field_media.data[0].relationships.field_media_image.data.attributes.uri.url;

    // Due to how Drupal handles internal urls, strip garbage
    const ctaLink = field_cta_link.uri.replace('internal:', '');

    return (
        <Link to={ctaLink}>
            <Card 
                title={field_cta_link.title} 
                caption={caption} 
                image={src} 
                variant="mini" 
                center 
            />
        </Link>
    );
};

export default IndexCard;