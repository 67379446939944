import React from 'react';
import parse from 'html-react-parser';
import { AccordionItem as UKAccordionItem } from 'dma-ui-kit';

const AccordionItem = props => {
    const { attributes } = props.paragraph;
    const title = attributes.field_accordion_title;
    const content = attributes.field_accordion_content.processed;

    return (
        <UKAccordionItem title={title}>
            {parse(content)}
        </UKAccordionItem>
    );
};

export default AccordionItem;