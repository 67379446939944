import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { Box, breakpoints, SearchResultsGrid } from 'dma-ui-kit';
import useBreakpoint from 'use-breakpoint';

const IndexBlock = props => {
    const { field_cards } = props.paragraph.relationships;
    const children = generateChildrenFromList(field_cards.data);
    const { breakpoint } = useBreakpoint(breakpoints);
    
    const getTilesPerRow = () => {
        switch(breakpoint) {
            case "mobile":
                return 1;
            case "tablet":
            case "tabletSmall":
                return 2;
            default:
                return 4;
        };
    };

    return (
        <Box w="80%" margin="auto" center="center">
            <SearchResultsGrid cardsPerRow={getTilesPerRow()}>
                {children}
            </SearchResultsGrid>
        </Box>
    );
};

export default IndexBlock;