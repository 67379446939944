import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';
import { Link } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';

import { Body, H6, red, SocialButtons, TwoColumn } from 'dma-ui-kit';

import NewsFooter from 'components/organisms/paragraph/NewsFooter';

const NewsPage = ({ pageManifest }) => {

    const { field_date } = pageManifest.attributes;

    const { field_contacts,
            field_exhibition,
            field_paragraph
    } = pageManifest.relationships;

    const contacts = field_contacts.data.map((item, idx) => {
        const contact_list = item.relationships.field_contacts.data.map((contact, cid) => {
            const { attributes } = contact;

            return (
                <>
                    <Body bold>{attributes.field_name}</Body>
                    <Body variant="small">
                        {attributes.field_organization}<br/>
                        {attributes.field_phone_number}<br/>
                    </Body>
                    <Link to='#' onClick={(e) => {
                            window.location.href = `mailto:${attributes.field_email}`;
                            e.preventDefault();
                    }}><Body variant="small" color={red}>Email</Body></Link>
                </>
            );
        });

        return (
            <>
                <H6>{item.attributes.field_title}</H6>
                {contact_list}
            </>
        );
    });

    const Children = generatePageParagraphs(field_paragraph.data);

    return (
        <>
            <TwoColumn variant='xl-left'>
                <div>
                {Children}
                <NewsFooter />
                </div>
                <div>
                    <H6>DATE</H6>
                    <Body variant="small">
                        {formatInTimeZone(Date.parse(field_date), "America/Chicago", "MMM do yyyy")}
                    </Body>
                    <H6>SHARE THIS</H6>
                    <SocialButtons color={red} />
                    {contacts}
                </div>
            </TwoColumn>
{/*             
            <div>Associated Exhibitions: {field_exhibition.data?.attributes.title} {field_exhibition.data?.attributes.title}</div>
            <div>More from the press room</div>
            <div>cards for stories go here</div> */}
        </>
    );
};

export default NewsPage;
