import { useState, useEffect } from 'react';
import axios from 'axios';

export const SearchableSection = ({ query, endpoint, children }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});

  let sectionActive = true;

  useEffect(() => {
    if (query) {
      const fn = async () => {
        setLoading(true);
        const nextResults = await doSearch(query, endpoint);     
        if (sectionActive)
        {
          setResults(nextResults);
          setLoading(false);
        }
      };
      fn();
    }

    return () => {
      sectionActive = false;
    };
  }, [query, endpoint]);

  const doSearch = async (data, url) => {
    try {
      const res = await axios.post(url, data);
      if (sectionActive)
      {
        const aggregations = {};
        for (var key in res.data.aggregations) {
          if (res.data.aggregations[key].buckets) {
            // primary aggregations
            aggregations[key] =  res.data.aggregations[key].buckets;
          }
          else {
            aggregations[key] =  {};
            for (var skey in res.data.aggregations[key]) {
              if (res.data.aggregations[key][skey].buckets) {
                // filtered aggregations
                aggregations[key][skey] =  res.data.aggregations[key][skey].buckets;
              }
              else
              {
                // computed aggregations (just values, no buckets)
                aggregations[key][skey] = res.data.aggregations[key][skey];
              }
            }
          }
        }

        const hits = res.data.hits.hits.map(hit => ({ ...hit._source }));
        const pages = Math.ceil(res.data.hits.total.value/data.size);
        const out = {
          aggregations: aggregations,
          hits: hits,
          resultCount: res.data.hits.total.value,
          pageCount: pages,
          currentPage: Math.floor(data.from / data.size) + 1,
        };
  
        return out;
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      {
        children({
          results,
          loading,
        })
      }
    </div>
  );
};
