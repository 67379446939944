import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import SiteContext from 'site/SiteContext';
import { Body, Box, Card, FlexBox } from 'dma-ui-kit';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import ImageNotFound from 'assets/resultmissing.png';

const RecordingLink = styled(Link)`
    padding: 0 10px;
`;

const RecordingList = (props) => {
    const { attributes, relationships } = props.paragraph;

    const limit =   (attributes.field_display_limit == -1) ? 1000 : 
                    (attributes.field_display_limit) ? attributes.field_display_limit : 10;

    const { data } = relationships.field_audio_category;
    const [categoryItems, setCategoryItems] = useState([]);

    const Container = styled(Box)`
        padding: 0 12px;
        @media (min-width: 1025px) {
            padding: 0 48px;
        }
        @media (max-width: 1024px) {
            padding: 0 24px;
        }    

        margin-bottom: 10px;
        display: block;
    `;

    const showRecordings = () => {
        const results = categoryItems.map((result, idx) => {
            return (
                <RecordingLink key={idx} to={{ pathname: `${result.url[0]}` }}>
                    <Card 
                        image={result.field_image ? result.field_image[0] : ImageNotFound } 
                        title={result.title[0]} 
                        alt={result.title[0]}
                        caption={formatInTimeZone(fromUnixTime(result.created[0]), 'America/Chicago', 'MMM do yyyy')}                                
                        variant="mini"
                    />
                </RecordingLink>
            );
        });
        return results;
    };

    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;

    const getCategoryItems = async (categoryList) => {
        const query = { "query": { "bool": { "must": [ { "query_string" : { "query": `${categoryList}`, "fields": ["field_audio_category"]} } ]}}, "sort": [ { "created": { "order" : "desc" }}], "size": `${limit}` };
        const res = await axios.post(searchEndpoint, query);

        const hits = res.data.hits.hits.map(hit => ({ ...hit._source }));
        setCategoryItems(hits);
    };

    const categoryList = data.attributes.drupal_internal__tid;
    useEffect(() => {
        getCategoryItems(categoryList);
    }, [categoryList]);    

    return (
        <Container>
            <FlexBox flexDirection="row" flexWrap="wrap">
                {showRecordings()}
            </FlexBox>            
        </Container>
    );
};

export default withRouter(RecordingList);
