import React from 'react';
import Modal from 'react-modal';
import { Viewer } from "react-iiif-viewer";
import { buildIIIFImageURL, buildIIIFInfoURL } from 'helpers/collection';
import useWindowDimensions from 'helpers/windowdimensions';
import { ObjectImage } from 'components/molecules/collection/ObjectImage';

const ModalViewer = ({ id, image }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const { height, width } = useWindowDimensions();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const modalStyle = {
        content: {
            bottom: 'auto',
            height: `${height}px`,
            left: '0',
            margin: 0,
            padding: 0,
            right: 'auto',
            top: '0',
            width: `${width}px`
        },
        overlay: {
            zIndex: '99'
        }
    };

    return (
        <>
            <ObjectImage
                alt={`Object ${id} Image ${image}`}
                title={`Object ${id} Image ${image}`}
                style={{ cursor: 'zoom-in' }}
                onclick={openModal}
                src={buildIIIFImageURL(id, image, { size: 'full'})}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={modalStyle}
                contentLabel="Example Modal"
                appElement={document.getElementById('root')}
            >
                <Viewer
                    style={modalStyle}
                    width={`${width}px`}
                    height={`${height}px`}
                    iiifUrl={buildIIIFInfoURL(id, image)}
                    modalCloseAction={closeModal}
                />
            </Modal>
        </>
    );
};

export default ModalViewer;