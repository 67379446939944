import React from 'react';
import styled from 'styled-components';
import { Box } from 'dma-ui-kit';
import { CrowdRiffGallery } from 'react-crowdriff-gallery';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 10px;
display: block;
`;

const CrowdriffEmbed = props => {
    const { attributes } = props.paragraph;
    const { field_script_id } = attributes;

    // Remove the prefix from the script ID to get the hash
    const hash = field_script_id.replace('cr-init__', '');

    return (
        <Container>
            <CrowdRiffGallery hash={hash} />
        </Container>
    );
};

export default CrowdriffEmbed;
