import { Body, Box, Caption, H1, H2, H3, H4, H5, H6, black } from 'dma-ui-kit';
import styled from 'styled-components';
import { colorMap } from 'helpers/colormap';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const ContentHeader = props => {
    const { attributes, relationships } = props.paragraph;
    const { field_caption_text } = attributes;

    const field_size = (relationships.field_size?.data?.attributes?.name) ? relationships.field_size.data.attributes.name : 'Body';
    const field_font_color = (relationships.field_font_color?.data?.attributes?.name) ? relationships.field_font_color.data.attributes.name : 'black';

    // Maps Taxonomy to Typography class
    const color = (colorMap[field_font_color]) ? colorMap[field_font_color] : black;

    const sizes = { 'Body': Body, 'Caption': Caption, 'H1': H1, 'H2': H2, 'H3': H3, 'H4': H4, 'H5': H5, 'H6': H6 };
    const DisplaySize = (sizes[field_size]) ? sizes[field_size] : Body;
    
    return (
        <>
            <Container>
                <DisplaySize color={color}>{field_caption_text}</DisplaySize>
            </Container>
        </>
    );
};

export default ContentHeader;
