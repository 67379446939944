import React from 'react';
import { Box, Body } from 'dma-ui-kit';

const Newsletter = props => {
    const { field_list_description, field_list_id, field_list_name } = props.paragraph.attributes;

    return (
        <Box mb="4px" padding="4px">
            <Body bold><input type="checkbox" name="NewsLetter" value={field_list_id} /> {field_list_name}</Body>
            <Body variant="small">{field_list_description}</Body>
        </Box>
    );
};

export default Newsletter;