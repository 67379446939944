import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';
import { Box, Body, H5, H6, TwoColumn } from 'dma-ui-kit';
import styled from 'styled-components';
import { translateInterfaceText } from 'helpers/translation';
import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const Container = styled(Box)`
  padding: 0 0 0 12px;
  @media (min-width: 1025px) {
      padding: 0 0 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 0 0 24px;
  }

  max-width: 1250px;
  margin-bottom: 20px;
  & h2 {
    margin: 5px 0;
  }
  & h5 {
    margin: 5px 0;
  }
`;

const PastExhibitionPage = ({ pageManifest }) => {

    const { field_attendance,
            field_credit_line, 
            field_curator,
            field_description,
            field_end_date,
            field_entrance_fee,
            field_location_name,
            field_organizer,
            field_other_venues,
            field_piction_exhibition_id,
            field_start_date,
            field_tms_exhibition_id,
            title
    } = pageManifest.attributes;

    const { field_paragraph
    } = pageManifest.relationships;

    const children = generatePageParagraphs(field_paragraph.data);

    return (
        <Container> 
            <TwoColumn variant='xl-left'>
                <div>
                    <H5>{title}</H5>
                    {field_description && <Body>{field_description}</Body>}
                    {field_credit_line && 
                        <>
                            <H6>{translateInterfaceText('Credit Line')}</H6>
                            <Body>{field_credit_line}</Body>
                        </>
                    }
                    {children}
                </div>
                <div>
                    {(field_start_date || field_end_date) && 
                    <>
                        <H6>{translateInterfaceText('Dates')}</H6>
                        {field_start_date && <Body variant="small">
                            {formatInTimeZone(Date.parse(field_start_date), 'UTC', 'MMM do, yyyy')}
                            {field_end_date !== null && ' to'}
                        </Body>}
                        {field_end_date && <Body variant="small">
                            {field_end_date !== null && formatInTimeZone(Date.parse(field_end_date), 'UTC', 'MMM do, yyyy')}
                        </Body>}
                    </>}

                    {field_attendance && 
                    <>
                        <H6>{translateInterfaceText('Attendance')}</H6>
                        <Body>{field_attendance}</Body>
                    </>}

                    {field_location_name && 
                    <>
                        <H6>{translateInterfaceText('Location')}</H6>
                        <Body>{field_location_name}</Body>
                    </>}

                    {field_entrance_fee > 0 && 
                    <>
                        <H6>{translateInterfaceText('Entry Fee')}</H6>
                        <Body>$ {field_entrance_fee}</Body>
                    </>}

                    {field_curator && 
                    <>
                        <H6>{translateInterfaceText('Curator')}</H6>
                        <Body>{field_curator}</Body>
                    </>}

                    {field_organizer && 
                    <>
                        <H6>{translateInterfaceText('Organizer')}</H6>
                        <Body>{field_organizer}</Body>
                    </>}

                    {field_other_venues && 
                        <>
                        <H6>{translateInterfaceText('Other Venues')}</H6>
                        <Body>{field_other_venues}</Body>
                    </>}

                </div>
            </TwoColumn>
        </Container>
    );
};

export default PastExhibitionPage;
