import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router } from 'react-router-dom';
import awsconfig from './aws-exports';
//import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css';
import 'react-notifications/lib/notifications.css';

Amplify.configure(awsconfig);

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <>
                <NotificationContainer />
                <App />
            </>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

