import { React, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { SiteContext } from 'site/SiteContext';
import { isBefore } from 'date-fns';
import { Alert } from 'dma-ui-kit';

const SiteAlert = () => {
    const { siteData } = useContext(SiteContext);
    const [index, setIndex] = useState(0);

    const activeAlerts = (siteData && siteData.site_alerts) ? siteData.site_alerts.filter(alert => 
        isBefore(Date.parse(alert.start), new Date) &&
        isBefore(new Date, Date.parse(alert.end))
    ): [];

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(index => index + 1);
        }, 5000);
    
        return () => clearInterval(interval);
      }, []);

    return (
        <>
            {(activeAlerts.length != 0) && 
                <Alert key={index} message={activeAlerts[index % activeAlerts.length].alert_text} />
            }
        </>
    );
};

export default withRouter(SiteAlert);