import { useContext } from 'react';
import { get } from 'lodash';
import { SiteContext } from 'site/SiteContext';

export const buildCrumbs = (alias) => {
    const { siteData } = useContext(SiteContext);

    if (get(siteData, 'aliases')) {
        const { aliases } = siteData;

        // Skip processing if alias is empty
        if (alias == '')
          return [];

        // Don't display crumbs on the home page
        if (alias == '/')
            return [];

        const crumbs = [];

        // Establish home page as initial crumb
        for (const item of aliases.filter(item => item.alias == siteData.front))
            crumbs.push({ label: item.title, url: '/'});

        const segments = alias.split('/');

        let lastpath = '';
        for (const element of segments) {
          let path = (lastpath == '/') ? lastpath + element : lastpath + '/' + element;
          lastpath = path;
    
          // Pack other crumbs
          for (const item of aliases.filter(item => item.alias == path)) {
            const itemTitle = (item.title.length > 20) ? item.title.substring(0, 20) + '...' : item.title;
            crumbs.push({ label: itemTitle, url: item.alias});
          }
        }

        return (crumbs);    
    }

    return [];
};
