import React, { useContext, useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, SiteSearchResult } from 'dma-ui-kit';
import styled from 'styled-components';
import SiteContext from 'site/SiteContext';
import { translateInterfaceText } from 'helpers/translation';
import { SearchableSection } from 'search/SearchableSection';
import { buildQuery, reducer as queryReducer } from 'search/queries';

const Container = styled(Box)`
  padding: 0 12px;
  @media (min-width: 1025px) {
      padding: 0 48px;
  }
  @media (max-width: 1024px) {
      padding: 0 24px;
  }    

  margin-bottom: 10px;
  display: block;
`;

const FeaturedNews = React.memo(() => {
  const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
  const envir = process.env.REACT_APP_ENV;
  const { searchPath } = useContext(SiteContext);
  const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;
  const history = useHistory();

  const [query, setQuery] = useState(null);

  const [options, dispatch] = useReducer(
    queryReducer,
    {  
      type: 'news',
      page: 1,
      term: '',
      limit: 5,
      filters: {}
    },  
  );

  useEffect(() => {
    const nextQuery = buildQuery(options);
    setQuery(nextQuery);
  }, [buildQuery, setQuery, options]);

  const labels = {
    readMore: translateInterfaceText('Read more')
  };

  return (
    <Container>
      <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/press-room"}>
        {({ results, loading }) => {
          
          return (
            <>
              {results?.hits?.length > 0 && 
                <>
                  <div style={{ display: "block", width: '80%', margin: 'auto' }}>
                    <div>
                      {results.hits.map((result, index) => (
                            <SiteSearchResult
                              key={`search-result-${index}`}
                              type={''}
                              title={result.title[0]}
                              body={result.processed && result.processed[0] ? result.processed[0] : ''}
                              action={() => history.push(result.url[0])}
                              actionLabel={labels.readMore}
                            />
                      ))}
                    </div>
                  </div>
                </>
              }
            </>
          );
        }}
      </SearchableSection>
    </Container>
  );
});

export default FeaturedNews;

