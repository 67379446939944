import React from 'react';
import { generatePageParagraphs } from 'cms/parsers';

const NewsletterManagement = ({ pageManifest }) => {

  const { field_paragraph } = pageManifest.relationships;
  const Children = generatePageParagraphs(field_paragraph.data);
  
  return (
    <> 
      {Children}
    </>
  );
};

export default NewsletterManagement;
