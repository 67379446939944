import { useContext } from 'react';
import { get } from 'lodash';
import { SiteContext } from 'site/SiteContext';

// Translate a string to a language
export const translateInterfaceText = (stringToTranslate) => {
    const { siteData } = useContext(SiteContext);

    if (get(siteData, 'translations')) {
        if (siteData.translations[stringToTranslate] !== undefined)
            return(siteData.translations[stringToTranslate]);
    }
    
    return(stringToTranslate);
};