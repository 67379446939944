import React from 'react';
import ContentBlock from 'dma-ui-kit';

const ContentBlockParagraph = props => {
    return (
        <>
            <ContentBlock
                headline={'Headline'}
                content={''}
                action={''}
            />
        </>
    );
};

export default ContentBlockParagraph;