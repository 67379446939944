import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, SlideShow, grey800 } from 'dma-ui-kit';
import { buildIIIFImageURL } from 'helpers/collection';
import { ObjectImage, ObjectTile } from './ObjectImage';
import ModalViewer from 'components/molecules/collection/ModalViewer';

const Container = styled(Box)`
padding: 0 12px;
@media (min-width: 1025px) {
    padding: 0 48px;
}
@media (max-width: 1024px) {
    padding: 0 24px;
}    

margin-bottom: 12px;
display: block;
text-align: center;
`;

const ImageContainer = styled(Box)`
    margin: auto;
    width: 100%;
`;

const CollectionSlideShow = (props) => {
    const { id, images, pyramid, action } = props;

    const [primaryImage, setPrimaryImage] = useState(0);

    const setImageIndex = (index) => {
        action(index);
        setPrimaryImage(index);
    };

    const showTiles = () => {
        const results = images.map((result, index) => {
            return (
                <ObjectTile key={index} src={buildIIIFImageURL(id, result, { size: 60 })} size="60" onclick={() => setImageIndex(index)} />
            );
        });
        return results;
    };    
      
    return (
        <Container>
            <ImageContainer>
                {pyramid ? 
                    <ModalViewer id={id} image={images[primaryImage]} /> :
                    <ObjectImage src={buildIIIFImageURL(id, images[primaryImage], { size: 'full' })} alt={`Object ${id} Image ${images[primaryImage]}`}  title={`Object ${id} Image ${images[primaryImage]}`} />
                }                
            </ImageContainer>
            <SlideShow color={grey800}>
                {showTiles()}
            </SlideShow>
        </Container>
    );
};

export default CollectionSlideShow;
