import React from 'react';
import { generateChildrenFromList } from 'cms/parsers';
import { Accordion as UKAccordion } from 'dma-ui-kit';

const Accordion = props => {
    const { paragraph } = props;

    const title = paragraph.attributes.field_section_title;
    const children = generateChildrenFromList(paragraph.relationships.field_accordion_items.data);
    
    return (
        <UKAccordion title={title}>
            {children}
        </UKAccordion>
    );
};

export default Accordion;