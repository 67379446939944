import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import SiteContext from 'site/SiteContext';

import { SearchableSection } from 'search/SearchableSection';
import { buildQuery, reducer as queryReducer } from 'search/queries';

import { Body, Box, breakpoints, Card, SearchResultsGrid } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import ImageNotFound from 'assets/resultmissing.png';

import { fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import styled from 'styled-components';
import useBreakpoint from 'use-breakpoint';

const NoLink = styled(Box)`
    padding: 0 10px;
`;

const EventLink = styled(Link)`
    padding: 0 10px;
`;

const UpcomingEvents = React.memo((props) => {
    const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
    const envir = process.env.REACT_APP_ENV;
    const { searchPath } = useContext(SiteContext);
    const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_content_index/_search`;
    const [query, setQuery] = useState(null);

    const { attributes, relationships } = props.paragraph;
    const { data } = relationships.field_event_category;
    const limit = attributes.field_display_limit ? attributes.field_display_limit : 10;
    const categories = data.map((item, index) => { return item.attributes.drupal_internal__tid; });
    const categoryList = categories.join(" OR ");

    const date = new Date();

    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'upcomingEvent',
        page: 1,
        term: '',
        limit: limit,
        filters: {
          categoryid: [ categoryList ],
          dateAfter: [ new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().slice(0, 10) ]
        }
      },  
    );

    const labels = {
      noUpcomingEvents: translateInterfaceText("No upcoming events")
    };

    const { breakpoint } = useBreakpoint(breakpoints);
    const getTilesPerRow = () => {
      switch(breakpoint) {
        case "mobile":
          return 1;
        case "tablet":
        case "tabletSmall":
          return 2;
        default:
          return 4;
      };
    };


    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);

    return (
      <Box w="80%" margin="auto" center="center">
        <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/press-room"}>
          {({ results, loading }) => {
            return (
              <SearchResultsGrid cardsPerRow={getTilesPerRow()}>
                {(results?.hits?.length > 0) ? 
                  results.hits.map((result, index) => (
                    <EventLink key={index} to={{ pathname: `${result.url[0]}` }}>
                      <Card 
                          image={result.thumbnail || result.featured_image || ImageNotFound} 
                          title={result.title[0]} 
                          alt={result.featured_image_alt && result.featured_image_alt[0]}
                          caption={(
                              <Body variant='small'>
                                  {formatInTimeZone(fromUnixTime(result.field_date_rrule[0]), 'America/Chicago', 'MMM do yyyy')}
                                  {(formatInTimeZone(fromUnixTime(result.field_date_rrule[0]), 'America/Chicago', 'MMM do yyyy') !=
                                    formatInTimeZone(fromUnixTime(result.date_rrule_end[0]), 'America/Chicago', 'MMM do yyyy') &&
                                    (<> - {formatInTimeZone(fromUnixTime(result.date_rrule_end[0]), 'America/Chicago', 'MMM do yyyy')}</>)
                                  )}
                                  <br/>
                                  {formatInTimeZone(fromUnixTime(result.field_date_rrule[0]), 'America/Chicago', 'h:mm aaa')} - {formatInTimeZone(fromUnixTime(result.date_rrule_end[0]), 'America/Chicago', 'h:mm aaa')}
                              </Body>
                          )}
                          variant="mini"
                      />
                    </EventLink>
                  )) : (
                    <NoLink>
                        <Card 
                            image={ImageNotFound } 
                            title={labels.noUpcomingEvents} 
                            alt={labels.noUpcomingEvents}
                            caption={''}
                            variant="mini"
                        />
                    </NoLink>
                  )}
              </SearchResultsGrid>                
            );
          }}
        </SearchableSection>
      </Box>
    );
});

export default UpcomingEvents;