import React from 'react';
import { Body, H5, red, Box } from 'dma-ui-kit';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import styled from 'styled-components';

const ProgramBox = styled(Box)`
    margin-top: 20px;
    width: 100%;

    padding: 0 12px;
    @media (min-width: 1025px) {
        padding: 0 48px;
    }
    @media (max-width: 1024px) {
        padding: 0 24px;
    }    
`;

const ProgramBody = styled(Body)`
    margin-bottom: 10px;
`;

const Programs = props => {
    const showPrograms = () => {
        const { data } = props.paragraph.relationships.field_programs;
        const results = data.map((result, index) => {
            const { attributes, relationships } = result;

            return (
                <Link key={index} to={{ pathname: attributes.alias}}>
                    <ProgramBody>{attributes.title}</ProgramBody>
                </Link>
            );
        });
        return results;
    };    

    return (
        <ProgramBox>
            <H5 color={red}>Programs</H5>
            {showPrograms()}
        </ProgramBox>
    );
};

export default withRouter(Programs);