import React from 'react';
import { withRouter } from 'react-router-dom';
import { SiteSearch, SearchContext as SearchUIProvider } from 'dma-ui-kit';
import { translateInterfaceText } from './translation';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const unpackFilterQuery = (query) => {
  if (!query || !query.includes('|||')) return undefined;

  const initialFilters = {};

  query.split('|||').forEach(filter => {
    if (filter.includes('||')) {
      const kvp = filter.split('||');
      initialFilters[kvp[0]] = (kvp[1].includes('|')) ? kvp[1].split('|') : [kvp[1]];
    }
  });
  
  return initialFilters;
};

export const filterQuery = (payload) => {
  let filterURIdata = '';

  for (const [key, value] of Object.entries(payload)) {
    const val = Array.isArray(value) ? value.join('|') : value;
    if (val.length > 0) filterURIdata += `${key}||${val}|||`;
  }

  return filterURIdata;
};

function Search (props) {
  const { children } = props;

  const searchLabels = { 
    action: translateInterfaceText('Search'),
    instructions: translateInterfaceText('Enter your search term below'),
    placeholder: translateInterfaceText('Enter search term')
  };

  const onsearch = term => { window.location.assign(`/search?s=${term}&p=1`); };

  return (
    <SearchUIProvider
        labels={searchLabels}
        onSearch={onsearch}
      >
        <SiteSearch />
        {children}
    </SearchUIProvider>
  );
}

export default withRouter(Search);
