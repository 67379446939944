import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import SiteContext from 'site/SiteContext';

import { SearchableSection } from 'search/SearchableSection';
import { buildQuery, reducer as queryReducer } from 'search/queries';
import { buildIIIFImageURL } from 'helpers/collection';

import { Box, breakpoints, Card, SearchResultsGrid } from 'dma-ui-kit';
import { translateInterfaceText } from 'helpers/translation';
import ImageNotFound from 'assets/resultmissing.png';

import styled from 'styled-components';
import useBreakpoint from 'use-breakpoint';

const NoLink = styled(Box)`
    padding: 0 10px;
`;

const ObjectPackage = React.memo((props) => {

  const baseSearchURL = process.env.REACT_APP_SEARCH_BASE_PATH;
  const envir = process.env.REACT_APP_COLLECTION_ENV;
  const { searchPath } = useContext(SiteContext);
  const searchEndpoint = `${baseSearchURL}${envir}${searchPath}_collection_objects/_search`;

   const [query, setQuery] = useState(null);

    const { attributes } = props.paragraph;
    const { field_tms_package_name } = attributes;
    const [options, dispatch] = useReducer(
      queryReducer,
      {  
        type: 'collection',
        page: 1,
        term: '',
        limit: 1000,
        filters: { 
          package: field_tms_package_name 
        }
      },  
    );

    const labels = {
      noObjectsFound: translateInterfaceText("No objects found")
    };

    const { breakpoint } = useBreakpoint(breakpoints);
    const getTilesPerRow = () => {
      switch(breakpoint) {
        case "mobile":
          return 1;
        case "tablet":
        case "tabletSmall":
          return 2;
        default:
          return 4;
      };
    };

    useEffect(() => {
      const nextQuery = buildQuery(options);
      setQuery(nextQuery);
    }, [buildQuery, setQuery, options]);

    return (
      <Box w="80%" margin="auto" center="center">
        <SearchableSection query={query} endpoint={searchEndpoint} historyPath={"/art/collection/search"}>
          {({ results, loading }) => {
            return (
              <SearchResultsGrid cardsPerRow={getTilesPerRow()}>
                {(results?.hits?.length > 0) ? 
                  results.hits.map((result, index) => {
                    const imgSrc = (result.primary_image == -1) ? ImageNotFound : buildIIIFImageURL(result.id, result.primary_image, {size: 200, type: 'square'} );
                    const constituent = result.constituents[0] ? `${result.constituents[0].name}` : ''; 
                    return (
                        <Link key={index} to={{ pathname: `/art/collection/object/${result.id}`}}>
                            <Card 
                                title={result.title} 
                                caption={constituent} 
                                image={imgSrc} 
                                variant="mini"
                            />
                        </Link>                        
                    );
                  }) : (
                    <NoLink>
                        <Card 
                            image={ImageNotFound } 
                            title={labels.noObjectsFound} 
                            alt={labels.noObjectsFound}
                            caption={''}
                            variant="mini"
                        />
                    </NoLink>
                  )}
              </SearchResultsGrid>                
            );
          }}
        </SearchableSection>
      </Box>
    );
});

export default ObjectPackage;